import React, { useEffect, useState, useRef } from "react";
// import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import SectionNavbar from "../../../components/NavigacijaSekcije";
import MainBanner from "../../../components/Baner";
import "../../../styles/global.css";
import { fetchCuriculumDetail } from "../../../services/apiService";
import DocumentsSection from "../../../components/DocumentCard";
import style from "./CiklusDetaljno.module.css";

const studyCycles = [
  {
    slug_bs: "prvi-ciklus-studija",
    slug_en: "first-cycle-of-studies",
  },
  {
    slug_bs: "drugi-ciklus-studija",
    slug_en: "second-cycle-of-studies",
  },
  {
    slug_bs: "treci-ciklus-studija",
    slug_en: "third-cycle-of-studies",
  },
];

// Section Component
const Section = ({ bgClass, header, items, id }) => (
  <section className={`${bgClass} py-5 `} id={id}>
    <div className="container">
      {header && (
        <div
          className="d-flex align-items-center pb-3 mb-5"
          style={{ borderBottom: "1px solid #DDDDDD" }}
        >
          <div className={style.headerCycleWrapper}></div>
          <h2 class="fs-6 fw-medium mb-0">{header}</h2>
        </div>
      )}
      <div className="row">
        <DocumentsSection documents={items} bgClass={bgClass} />
      </div>
    </div>
  </section>
);

const CurriculumDetail = () => {
  const [data, setData] = useState(null);
  const { slug } = useParams();
  const bannerRef = useRef(null);
  const slugToUse = getSlugForLanguage(slug);

  const navigate = useNavigate();

  function getSlugForLanguage(currentSlug) {
    const language = localStorage.getItem("language"); // Get language from localStorage

    // Find the department object that matches the currentSlug
    const department = studyCycles.find(
      (dept) => dept.slug_bs === currentSlug || dept.slug_en === currentSlug
    );

    // If department is found, return the slug based on the language
    if (department) {
      return language === "en" ? department.slug_en : department.slug_bs;
    } else {
      // Handle case where the department is not found
      console.error("Department not found for slug:", currentSlug);
      return null;
    }
  }

  window.history.replaceState(
    {},
    "",
    `${
      localStorage.getItem("language") === "en" ? "/en" : ""
    }/nastava/${slugToUse}`
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchCuriculumDetail(slugToUse);
        setData(data);
      } catch (error) {
        navigate("/not-found", { replace: true });
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [slug]);

  if (!data) return <p>Loading...</p>;

  const items = [
    { name: "O ciklusu", nameEng: "About cycle", link: "#ociklusu" },
    {
      name: "Nastavni plan",
      nameEng: "Curriculum",
      link: "#nastavniplan",
    },
    {
      name: "Silabusi",
      nameEng: "Silabus",
      link: "#silabus",
    },
    {
      name: "Rasporedi nastave",
      nameEng: "Class Schedules",
      link: "#rasporedinastave",
    },
    {
      name: "Rasporedi polaganja ispita",
      nameEng: "Examination Schedules",
      link: "#rasporedpolaganjaispita",
    },

    {
      name: "Ostali dokumenti",
      nameEng: "Other documents",
      link: "#ostalidokumenti",
    },
  ];

  // Meta Tags
  document.title = data.meta_title;
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", data.meta_description);

  return (
    <div>
      <div ref={bannerRef}>
        <MainBanner
          title={data.title}
          bannerImage={data.cover}
          description={data.header}
        />
      </div>
      <SectionNavbar items={items} observeRef={bannerRef} />
      <div className="bg-white">
        <section className="py-5 container" id="ociklusu">
          {/* <h1>{data.title}</h1> */}
          <div
            dangerouslySetInnerHTML={{ __html: data.content }}
            className="html__wrapper"
          />
        </section>
        {/* Section Helper */}

        {/* Conditional Sections */}
        {data.nastavni_planovi.length > 0 && (
          <Section
            bgClass="bg-light"
            items={data.nastavni_planovi}
            id="nastavniplan"
          />
        )}
        {data.silabusi.length > 0 && (
          <Section bgClass="bg-white" items={data.silabusi} id="silabus" />
        )}
        {data.ostali.length > 0 && (
          <Section
            bgClass="bg-white"
            items={data.ostali}
            id="ostalidokumenti"
          />
        )}
        {data.rasporedi_nastave.length > 0 && (
          <Section
            bgClass="bg-light"
            header={data.header_raspored_nastave}
            items={data.rasporedi_nastave}
            id="rasporedinastave"
          />
        )}
        {data.rasporedi_ispita.length > 0 && (
          <Section
            bgClass="bg-light"
            header={data.header_raspored_ispita}
            items={data.rasporedi_ispita}
            id="rasporedpolaganjaispita"
          />
        )}
      </div>
    </div>
  );
};

export default CurriculumDetail;

import React, { useEffect, useState, useContext } from "react";
import {
  Table,
  Pagination,
  Dropdown,
  DropdownButton,
  InputGroup,
  FormControl,
  Spinner,
} from "react-bootstrap";
import { FaDownload } from "react-icons/fa";
import { fetchAllDocuments, getVijeceFile } from "../../../services/apiService";
import MainBanner from "../../../components/Baner";
import dokumentiLogo from "../../../assets/dokumenti.jpg";
import { AuthContext } from "../../../contexts/AuthContext";
import style from "./Dokumenti.module.css";

const Dokumenti = () => {
  // const [language, setLanguage] = useState("bs");
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [selectedFirstFilter, setSelectedFirstFilter] =
    useState("Svi dokumenti");
  const [selectedSecondFilter, setSelectedSecondFilter] =
    useState("Sve kategorije");
  const [searchQuery, setSearchQuery] = useState("");

  const { isAuthenticated } = useContext(AuthContext);

  const language = localStorage.getItem("language") || "bs";

  const firstFilterOptions = [
    "Svi dokumenti",
    "Javnost rada fakulteta",
    "Propisi",
  ];

  const secondFilterOptions = {
    "Svi dokumenti": [
      "Sve kategorije",

      "Zakoni",
      "Izvještaji",
      "Propisi",
      "Pravilnici",
      "Odluke",
      "Obrasci",
      "Konkursi",
      "Zahtjevi",
      "Ostalo",
    ],
    Propisi: [
      "Bosne i Hercegovine",
      "Federacije Bosne i Hercegovine",
      "Kantona Sarajevo",
      "Univerziteta u Sarajevu",
    ],
    "Javnost rada fakulteta": [
      "Izvještaji komisije za izbore u akademska zvanja",
      "Sjednice Vijeća",
      "Materijali za Vijeća",
      "Konkursi za izbore u akademska zvanja",
      "Konkursi za prijem neakademskog osoblja",
      "Angažman akademskog osoblja",
      "Naknade zaposlenicima fakulteta",
      "Sporazumi o akademskoj saradnji",
      "Odgovori na zastupnička pitanja",
    ],
  };

  const fetchDocuments = async () => {
    try {
      setLoading(true);
      const data = await fetchAllDocuments(
        page,
        searchQuery,
        selectedFirstFilter,
        selectedSecondFilter
      );
      setDocuments(data.results);
      setCount(data.count);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching documents:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [selectedFirstFilter, selectedSecondFilter, page, searchQuery]);

  const handleFirstFilterChange = (filter) => {
    setSelectedFirstFilter(filter);
    setPage(1); // Reset to first page on filter change

    // Automatically set selectedSecondFilter based on the selectedFirstFilter
    if (filter === "Propisi" || filter === "Javnost rada fakulteta") {
      setSelectedSecondFilter(secondFilterOptions[filter][0]);
    } else {
      setSelectedSecondFilter("Sve kategorije");
    }
  };

  const handleSecondFilterChange = (filter) => {
    setSelectedSecondFilter(filter);
    setPage(1);
  };

  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    setPage(newPage);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setPage(1); // Reset to first page on search
  };

  const handleDownloadClick = async (file_name) => {
    try {
      // Show loading or feedback to the user if needed
      setLoading(true);

      // Call the getVijeceFile function with the slug from file_file_name
      const response = await getVijeceFile(file_name); // Assuming getVijeceFile returns the file or data

      setLoading(false);
    } catch (error) {
      console.error("Error downloading file:", error);
      setLoading(false);
    }
  };

  console.log(documents, "svi dokumenti");

  const totalPages = Math.ceil(count / 10);
  const renderPagination = () => (
    <Pagination
      style={{ backgroundColor: "#F3F6F8" }}
      className="justify-content-end"
    >
      <Pagination.Prev
        onClick={() => handlePageChange(page - 1)}
        disabled={page === 1}
      />
      {[...Array(totalPages)].map((_, index) => (
        <Pagination.Item
          key={index + 1}
          active={index + 1 === page}
          onClick={() => handlePageChange(index + 1)}
        >
          {index + 1}
        </Pagination.Item>
      ))}
      <Pagination.Next
        onClick={() => handlePageChange(page + 1)}
        disabled={page === totalPages}
      />
    </Pagination>
  );

  document.title =
    language === "bs"
      ? "Dokumenti | Mašinski fakultet Univerzitet u Sarajevu"
      : "Documents | Mechanical Engineering University of Sarajevo";
  document
    .querySelector('meta[name="description"]')
    .setAttribute(
      "content",
      "Dokumenti-Mašinski fakultet Univerziteta u Sarajevu"
    );

  return (
    <>
      <MainBanner
        bannerImage={dokumentiLogo}
        title={language === "bs" ? "Dokumenti" : "Documents"}
        description={
          language === "bs"
            ? "Dokumenti Mašinskog fakulteta - Univerziteta u Sarajevu. Pronađi i preuzmi sve informacije o radu fakulteta na jednom mjestu."
            : "Documents of the Faculty of Mechanical Engineering - University of Sarajevo. Find and download all information about the work of the faculty in one place."
        }
      />
      <div className="container__wrapper bg-white ">
        <div className="container">
          <div className="d-flex flex-wrap justify-content-between mb-4">
            <div className="d-flex flex-wrap flex-lg-nowrap gap-2 col-12 col-lg-6">
              {/* First Dropdown Button */}
              <div className="col-12 col-lg-auto mb-2 mb-lg-0">
                <DropdownButton
                  title={selectedFirstFilter}
                  variant="white"
                  onSelect={handleFirstFilterChange}
                  className={`${style.dropDownStyle} w-100 `}
                >
                  {firstFilterOptions.map((option) => (
                    <Dropdown.Item
                      key={option}
                      eventKey={option}
                      className="w-100"
                      style={{ width: "100%" }}
                    >
                      {option}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </div>

              {/* Second Dropdown Button */}
              <div className="col-12 col-lg-auto mb-2 mb-lg-0">
                <DropdownButton
                  title={selectedSecondFilter}
                  variant="white"
                  onSelect={handleSecondFilterChange}
                  className={`${style.dropDownStyle} w-100`}
                >
                  {secondFilterOptions[selectedFirstFilter].map((option) => {
                    // Conditionally render the "Materijali za Vijeća" option
                    if (option === "Materijali za Vijeća" && !isAuthenticated) {
                      return null; // Don't render if the user is not logged in
                    }

                    return (
                      <Dropdown.Item
                        key={option}
                        eventKey={option}
                        className="w-100"
                        style={{
                          fontWeight:
                            option === "Materijali za Vijeća" ? 600 : "normal",
                        }}
                      >
                        {option}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
              </div>
            </div>

            {/* Search Input */}
            <div className="col-12 col-lg-auto mt-2 mt-lg-0">
              <InputGroup style={{ width: "100%" }}>
                <FormControl
                  placeholder={language === "bs" ? "Pretraži" : "Search"}
                  value={searchQuery}
                  onChange={handleSearch}
                  class={style.searchInputStyle}
                />
              </InputGroup>
            </div>
          </div>

          <div className="d-flex justify-content-center"></div>

          <Table hover style={{ borderTop: "none" }}>
            <thead>
              <tr style={{ backgroundColor: "#F3F6F8" }}>
                <th class={style.tableHeadingStyle}>
                  {language === "bs" ? "Naziv" : "Title"}
                </th>

                {selectedSecondFilter !== "Materijali za Vijeća" && (
                  <th class={style.tableHeadingStyle}>
                    {language === "bs" ? "Kategorija" : "Category"}
                  </th>
                )}
                <th class={style.tableHeadingStyle}>
                  {language === "bs"
                    ? selectedSecondFilter === "Materijali za Vijeća"
                      ? "Datum objavljivanja"
                      : "Datum"
                    : selectedSecondFilter === "Materijali za Vijeća"
                    ? "Publication Date"
                    : "Date"}
                </th>
                <th class={style.tableHeadingStyle}>
                  {language === "bs" ? "Preuzmi" : "Download"}
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="4" className="text-center">
                    <div className="d-flex justify-content-center align-items-center my-5">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  </td>
                </tr>
              ) : documents.length === 0 ? (
                <tr>
                  <td colSpan="4" className="text-center">
                    {language === "bs" ? "Nema podataka" : "No results"}
                  </td>
                </tr>
              ) : (
                documents.map((doc) => (
                  <tr
                    key={doc.title}
                    style={{ borderBottom: "1px solid #DDDDDD" }}
                  >
                    <td class={style.tableDataStyle}>{doc.title}</td>
                    {selectedSecondFilter !== "Materijali za Vijeća" && (
                      <td class={style.tableDataStyle}>{doc.doc_type}</td>
                    )}
                    <td class={style.tableDataStyle}>{doc.date}</td>
                    <td
                      class={style.tableDataStyle}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        borderBottom: "none",
                      }}
                    >
                      {selectedSecondFilter === "Materijali za Vijeća" ? (
                        <button
                          onClick={() => handleDownloadClick(doc.file_name)}
                          class={style.downloadButtonStyle}
                        >
                          <FaDownload />
                        </button>
                      ) : (
                        <a
                          href={doc.file_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "black" }}
                        >
                          <FaDownload />
                        </a>
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>

          <div className="d-flex justify-content-end">{renderPagination()}</div>
        </div>
      </div>
    </>
  );
};

export default Dokumenti;

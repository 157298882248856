export const getLocalizedPath = (path) => {
  const language = localStorage.getItem("language") || "bs"; // Default to "bs"
  if (language === "en") {
    if (!path.startsWith("/en")) {
      return `/en${path}`;
    }
    return path; // Return as-is if already localized
  }
  return path;
};

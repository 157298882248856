import React, { useEffect, useState } from "react";
import "../../styles/global.css";
import { fetchDekanatIzjave } from "../../services/apiService";
import ButtonComponent from "../../components/ButtonComponent";
import { Link } from "react-router-dom";
import DekanatStatementsShared from "../../components/DekanatIzjaveShared";

const DekanatStatements = () => {
  const [statements, setStatements] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchDekanatIzjave(); // Fetch data using the service function
        setStatements(data);
      } catch (error) {
        // Handle any errors
        console.error("Error fetching news:", error);
      }
    };

    loadData(); // Call the function to load the data
  }, []);

  return (
    <div className="container__wrapper bg-light" id="rijeciuprave">
      <div className="container">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mt-2 mb-4">
          <div className="col-md-9">
            <h2 className="section_title">
              {localStorage.getItem("language") === "bs"
                ? "Riječi dekana i prodekana"
                : "Words from the Dean and Vice Dean"}
            </h2>
            <p className="section_subtitle">
              {localStorage.getItem("language") === "bs"
                ? "Inspirativne poruke i vizije menadžmenta Mašinskog fakulteta Univerziteta u Sarajevu. Pročitajte više o njihovim planovima, ciljevima i strateškim smjernicama za budućnost."
                : "Inspirational messages and visions of the management of the Faculty of Mechanical Engineering, University of Sarajevo. Read more about their plans, goals and strategic directions for the future"}
            </p>
          </div>

          <Link
            className="section__buttons mt-2"
            to={`/fakultet/nastavno-osoblje`}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <ButtonComponent
              label={
                localStorage.getItem("language") === "bs"
                  ? "Osoblje fakulteta →"
                  : "All staff →"
              }
              path="/dekanat-izjave"
              backgroundColor={"#051435"}
              color={"#FFFFFF"}
            />
          </Link>
        </div>
        <DekanatStatementsShared />
      </div>
    </div>
  );
};

export default DekanatStatements;

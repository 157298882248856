import React from "react";
import "../../styles/global.css";
import DekanatStatementsShared from "../../components/DekanatIzjaveShared";

const DekanatStatements = () => {
  return (
    <div className=" container__wrapper bg-white">
      <div className="container">
        <DekanatStatementsShared />
      </div>
    </div>
  );
};

export default DekanatStatements;

import React from "react";
import "../../styles/global.css";
import CycleComponent from "../../components/CiklusiKomponenta";

const FacultyCycles = () => {
  return (
    <div className="bg-white">
      <div className="container container__wrapper">
        <CycleComponent />
      </div>
    </div>
  );
};

export default FacultyCycles;

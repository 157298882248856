import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Pagination,
  Spinner,
} from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import MainBanner from "../../../components/Baner";
import ButtonComponent from "../../../components/ButtonComponent";
import { fetchAllNovosti } from "../../../services/apiService";
import novostiBanner from "../../../assets/novosti.jpg";
import style from "./NovostiLista.module.css";

const NewsComponent = () => {
  const [novosti, setNovosti] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [titleFilter, setTitleFilter] = useState(""); // State for title filter
  const [newsNum, setNewsNum] = useState(0);
  const itemsPerPage = 6;
  const [language, setLanguage] = useState("bs");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    localStorage.removeItem("newsDetailSlugs");
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const data = await fetchAllNovosti(currentPage, titleFilter);
      setNewsNum(data.count);
      setNovosti(data.results);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage, titleFilter);
  }, [currentPage, titleFilter]);

  const handlePageChange = (page) => {
    window.scrollTo(0, 0);
    setCurrentPage(page);
  };
  const handleTitleFilterChange = (e) => {
    setTitleFilter(e.target.value); // Update titleFilter state when input changes
    navigate("?page=1");
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(newsNum / itemsPerPage);

  document.title =
    language === "bs"
      ? "Novosti | Mašinski fakultet Univerzitet u Sarajevu"
      : "News | Mechanical Engineering University of Sarajevo";
  document
    .querySelector('meta[name="description"]')
    .setAttribute(
      "content",
      "Novosti-Mašinski fakultet Univerziteta u Sarajevu"
    );

  return (
    <>
      <MainBanner
        title={language === "bs" ? "Novosti" : "News"}
        styleImage={true}
        bannerImage={novostiBanner}
        description={
          language === "bs"
            ? "Najnovije vijesti i događaji sa Mašinskog fakulteta - Univerziteta u Sarajevu. Budi informisan o svim važnim dešavanjima."
            : "The latest news and events from the Faculty of Mechanical Engineering Sarajevo. Be informed about all important events."
        }
      />
      <div class="container__wrapper bg-white">
        <Container>
          <div className="d-flex justify-content-end mb-4">
            <InputGroup style={{ width: "100%", maxWidth: "240px" }}>
              <FormControl
                placeholder={
                  localStorage.getItem("language") === "bs"
                    ? "Pretraži"
                    : "Search"
                }
                aria-label="Search"
                className="search-bar"
                value={titleFilter}
                onChange={handleTitleFilterChange}
              />
            </InputGroup>
          </div>

          <div className="border-top pt-4">
            <Row className="gy-4">
              {isLoading ? (
                <div className="d-flex justify-content-center my-5">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : novosti.length === 0 ? (
                <div className="d-flex justify-content-center align-items-center h-100">
                  <p>
                    {localStorage.getItem("language") === "bs"
                      ? "Nema podataka"
                      : "No data"}
                  </p>
                </div>
              ) : (
                novosti.slice(0, 6).map((news) => (
                  <Col
                    key={news.slug}
                    xs={12}
                    sm={6}
                    md={4}
                    onClick={() =>
                      localStorage.setItem(
                        "detailSlug",
                        JSON.stringify({
                          bsSlug: news.slug_bs,
                          enSlug: news.slug_en,
                        })
                      )
                    }
                  >
                    <Link
                      to={`/aktuelnosti/novosti/${news.slug}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <div class={style.cardWrapper}>
                        <img
                          src={news.image}
                          alt={news.title}
                          class={style.imageStyle}
                        />
                        <div class={style.newsDate}>{news.date}</div>
                        <div class={style.newsContent}>
                          <h5>
                            {news.title.length > 95
                              ? news.title.substring(0, 95) + "..."
                              : news.title}
                          </h5>

                          <p class={style.newsSummary}>
                            {news.summary.length > 140
                              ? news.summary.substring(0, 180) + "..."
                              : news.summary}
                          </p>
                          <ButtonComponent
                            label={
                              localStorage.getItem("language") === "bs"
                                ? "Pročitaj →"
                                : "Read more →"
                            }
                            color="#000"
                            backgroundColor="#9FDAF8"
                          />
                        </div>
                      </div>
                    </Link>
                  </Col>
                ))
              )}
            </Row>
          </div>

          <div className="d-flex justify-content-center mt-4">
            <Pagination>
              <Pagination.Prev
                onClick={() =>
                  currentPage > 1 && handlePageChange(currentPage - 1)
                }
              />
              {[...Array(totalPages).keys()].map((_, i) => (
                <Pagination.Item
                  key={i + 1}
                  active={i + 1 === currentPage}
                  onClick={() => handlePageChange(i + 1)}
                >
                  {i + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={() =>
                  currentPage < totalPages && handlePageChange(currentPage + 1)
                }
              />
            </Pagination>
          </div>
        </Container>
      </div>
    </>
  );
};

export default NewsComponent;

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const loginUser = async (emailOrUsername, password) => {
  try {
    const response = await fetch(`${BASE_URL}/api/user/token/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email_or_username: emailOrUsername,
        password: password,
      }),
    });

    const data = await response.json();

    if (!response.ok) {
      throw data; // Throw the error to be caught in the calling function
    }

    return data; // Return the successful response data
  } catch (error) {
    throw error; // Re-throw error to be handled in the component
  }
};

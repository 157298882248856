import React, { useState, useEffect, useContext } from "react";
import "./NavigacijaStranice.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import { Link, useNavigate } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Dropdown,
  Toast,
} from "react-bootstrap";
import { ReactComponent as Example } from "../assets/icons/sing-in.svg";
import { ReactComponent as OnamaIcon } from "../assets/icons/onama.svg";
import { ReactComponent as NastavnoOsobljeIcon } from "../assets/icons/nastavno.svg";
import { ReactComponent as DokumentiIcon } from "../assets/icons/dokumenti.svg";
import { ReactComponent as StudentskaSluzbaIcon } from "../assets/icons/studentska.svg";
import { ReactComponent as JavneNabavkeIcon } from "../assets/icons/javnenabavke.svg";
import { ReactComponent as HistorijaIcon } from "../assets/icons/historija.svg";
import { ReactComponent as KontaktIcon } from "../assets/icons/kontakt.svg";

import { ReactComponent as ObavijestiIcon } from "../assets/icons/obavijest.svg";
import { ReactComponent as NovostiIcon } from "../assets/icons/novosti.svg";
import { ReactComponent as SeminariIcon } from "../assets/icons/seminari.svg";
import { ReactComponent as KoneferencijeIcon } from "../assets/icons/konferencija.svg";
import { ReactComponent as MasinijadaIcon } from "../assets/icons/masinijada.svg";
import { ReactComponent as FondoviIcon } from "../assets/icons/fondovi.svg";

import { ReactComponent as RezultatiiIcon } from "../assets/icons/rezultati.svg";
import { ReactComponent as StudentskaUnijaIcon } from "../assets/icons/studentska.svg";
import { ReactComponent as StudentskiBlogIcon } from "../assets/icons/studentski-blog.svg";
import { ReactComponent as BibliotekaIcon } from "../assets/icons/biblioteka.svg";

import { ReactComponent as PrviCiklusIcon } from "../assets/icons/prvi-ciklus.svg";
import { ReactComponent as DrugiCiklusIcon } from "../assets/icons/drugi-ciklus.svg";
import { ReactComponent as TreciCiklusIcon } from "../assets/icons/treci-ciklus.svg";

import { ReactComponent as KatedraFizikaMatematikaIcon } from "../assets/icons/matematika.svg";
import { ReactComponent as KatedraMehanikaIcon } from "../assets/icons/icon-mehanika-bijela.svg";
import { ReactComponent as OdsjekMasinskeKonstrukcijeIcon } from "../assets/icons/icon-konstrukcije-bijela.svg";
import { ReactComponent as OdsjekMasinskiProizvodniInzinjeringIcon } from "../assets/icons/icon-proizvodni-bijela.svg";
import { ReactComponent as OdsjekIndustrijskoInzinjerstvoMenadzmentIcon } from "../assets/icons/menadzment.svg";
import { ReactComponent as OdsjekEnergetikaProcesnatehnikaOkolinskoInzinjerstvoIcon } from "../assets/icons/energetika.svg";
import { ReactComponent as OdsjekTehnologijaDrvetaIcon } from "../assets/icons/drvo.svg";
import { ReactComponent as OdsjekMotoriVozilaIcon } from "../assets/icons/icon-motori-bijela_4.svg";
import { ReactComponent as OdsjekOdbrambenatehnologijaIcon } from "../assets/icons/odbrambene.svg";

import { ReactComponent as CoursewareIcon } from "../assets/icons/coursewear.svg";

import { ReactComponent as OprojektimaIcon } from "../assets/icons/projekt.svg";
import { ReactComponent as ProjektiRECDIIcon } from "../assets/icons/state.svg";

import { ReactComponent as EUNSAIcon } from "../assets/icons/unsa.svg";
import { ReactComponent as EBibliotekaIcon } from "../assets/icons/ebiblioteka.svg";
import { ReactComponent as WebEmailIcon } from "../assets/icons/mail.svg";

import LoginModal from "./LoginPage/LoginModal";

import { ReactComponent as LinkedIncon } from "../assets/icons/linkedin.svg";

import { AuthContext } from "../contexts/AuthContext";

import Logo from "../assets/mef_logo_white.png";
import { useLocation } from "react-router-dom";
import "./NavigacijaStranice.css";

const translations = {
  bs: {
    nazivUniverziteta: "Univerzitet u Sarajevu",
    nazivFakulteta: "Mašinski fakultet",
    pocetna: "Početna",
    fakultet: "Fakultet",
    oNama: "O nama",
    nastavnoOsoblje: "Nastavno osoblje",
    dokumenti: "Dokumenti",
    studentskaSluzba: "Studentska služba",
    javneNabavke: "Javne nabavke",
    historija: "Historija",
    kontakt: "Kontakt",
    aktuelnosti: "Aktuelnosti",
    obavijesti: "Obavijesti",
    novosti: "Novosti",
    seminari: "Seminari",
    konferencije: "Konferencije",
    masinijada: "Mašinijade",
    fondovi: "Fondovi",
    studenti: "Studenti",
    rezultatiIspita: "Rezultati ispita",
    studentskaUnija: "Studentska unija",
    biblioteka: "Biblioteka",
    studentskiBlog: "Studentski blog",
    nastava: "Nastava",
    ciklusi: "Ciklusi",
    prviCiklus: "Prvi ciklus",
    drugiCiklus: "Drugi ciklus",
    treciCiklus: "Treći ciklus",
    odsjeci: "Odsjeci",
    fizikaMatematika: "Katedra za fiziku i matematiku",
    mehanika: "Katedra za mehaniku",
    masinskeKonstrukcije: "Odsjek za mašinske konstrukcije",
    masinskiProizvodni: "Odsjek za mašinski proizvodni inžinjering",
    industrijskoMenadzment: "Odsjek za industrijsko inžinjerstvo i menadžment",
    energetikaOkolinsko:
      "Odsjek za energetiku, procesnu tehniku i okolinsko inžinjerstvo",
    tehnologijaDrveta: "Odsjek za tehnologiju drveta",
    motoriVozila: "Odsjek za motore i vozila",
    odbrambenaTehnologija: "Odsjek odbrambene tehnologije",
    ostalo: "Ostalo",
    courseware: "Courseware",
    projekti: "Projekti",
    oProjektima: "O projektima",
    realizovaniProjekti: "Realizovani projekti",
    projektRECDI: "Projekt RECDI",
    iduciProjekti: "Idući projekat koji dođe",
    linkovi: "Linkovi",
    eUNSA: "eUNSA",
    eBiblioteka: "e-Biblioteka",
    webMail: "Web Mail",
    linkedin: "LinkedIn",
  },

  en: {
    nazivUniverziteta: "University of Sarajevo",
    nazivFakulteta: "Mechanical Engineering",
    pocetna: "Home",
    fakultet: "Faculty",
    oNama: "About Us",
    nastavnoOsoblje: "Staff",
    dokumenti: "Documents",
    studentskaSluzba: "Student Services",
    javneNabavke: "Public Procurement",
    historija: "History",
    kontakt: "Contact",
    aktuelnosti: "News",
    obavijesti: "Announcements",
    novosti: "News",
    seminari: "Seminars",
    konferencije: "Conferences",
    masinijada: "Masinijade",
    fondovi: "Funds",
    studenti: "Students",
    rezultatiIspita: "Exam Results",
    studentskaUnija: "Student Union",
    biblioteka: "Library",
    studentskiBlog: "Student Blog",
    nastava: "Study",
    ciklusi: "Cycles",
    prviCiklus: "First Cycle",
    drugiCiklus: "Second Cycle",
    treciCiklus: "Third Cycle",
    odsjeci: "Departments",
    fizikaMatematika: "Department of Physics and Mathematics",
    mehanika: "Departments of Solid Mechanics",
    masinskeKonstrukcije: "Department of Mechanical Design",
    masinskiProizvodni: "Department of Machinery Production Engineering",
    industrijskoMenadzment:
      "Department of Industrial Engineering and Management",
    energetikaOkolinsko:
      "Department of Energy, Process Engineering and Environmental Engineering",
    tehnologijaDrveta: "Department of Wood Technology",
    motoriVozila: "Department of Vehicle Engineering",
    odbrambenaTehnologija: "Department of Defense Technology",
    ostalo: "Other",
    courseware: "Courseware",
    projekti: "Projects",
    oProjektima: "About Projects",
    realizovaniProjekti: "Completed Projects",
    projektRECDI: "Project RECDI",
    iduciProjekti: "Next Project",
    linkovi: "Links",
    eUNSA: "eUNSA",
    eBiblioteka: "e-Biblioteka",
    webMail: "Web Mail",
    linkedin: "LinkedIn",
  },
};

const MyNavbar = () => {
  const [isFakultetDropdownOpen, setIsFakultetDropdownOpen] = useState(false);
  const [isAktuelnostiDropdownOpen, setIsAktuelnostiDropdownOpen] =
    useState(false);
  const [isStudentiDropdownOpen, setIsStudentiDropdownOpen] = useState(false);
  const [isNastavaDropdownOpen, setIsNastavaDropdownOpen] = useState(false);
  const [isProjektiDropdownOpen, setIsProjektiDropdownOpen] = useState(false);
  const [isPortaliDropdownOpen, setIsPortaliDropdownOpen] = useState(false);

  const [showToast, setShowToast] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const { isAuthenticated, user, logout, userInitials, profileImage } =
    useContext(AuthContext);

  // // Delay timers for each dropdown
  // const [homeTimeout, setHomeTimeout] = useState(null);
  // const [aboutTimeout, setAboutTimeout] = useState(null);
  // const [servicesTimeout, setServicesTimeout] = useState(null);

  const [isNavbarExpanded, setIsNavbarExpanded] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const isPocetna = location.pathname === "/" || location.pathname === "/en/";

  const [isScrolled, setIsScrolled] = useState(false);
  const [language, setLanguage] = useState("bs");

  if (
    localStorage.getItem("language") === null &&
    !location.pathname.includes("/en")
  ) {
    localStorage.setItem("language", "bs");
    setLanguage("bs");
  }
  if (
    localStorage.getItem("language") === null &&
    location.pathname.includes("/en")
  ) {
    localStorage.setItem("language", "en");
    setLanguage("en");
  }

  const mobileView = window.innerWidth < 1000;

  const handleFakultetMouseEnter = () => {
    setIsFakultetDropdownOpen(true);
  };

  const handleFakultetMouseLeave = () => {
    setIsFakultetDropdownOpen(false);
  };

  const handleAktuelnostiMouseEnter = () => {
    setIsAktuelnostiDropdownOpen(true);
  };

  const handleAktuelnostiMouseLeave = () => {
    setIsAktuelnostiDropdownOpen(false);
  };

  const handleNastavaMouseEnter = () => {
    setIsNastavaDropdownOpen(true);
  };

  const handleNastavaMouseLeave = () => {
    setIsNastavaDropdownOpen(false);
  };

  const handleProjektiMouseEnter = () => {
    setIsProjektiDropdownOpen(true);
  };

  // Handle mouse leave for About dropdown with delay
  const handleProjektiMouseLeave = () => {
    setIsProjektiDropdownOpen(false);
  };

  const handleStudentiMouseEnter = () => {
    setIsStudentiDropdownOpen(true);
  };

  const handleStudentiMouseLeave = () => {
    setIsStudentiDropdownOpen(false);
  };

  const handlePortaliMouseEnter = () => {
    setIsPortaliDropdownOpen(true);
  };

  const handlePortaliMouseLeave = () => {
    setIsPortaliDropdownOpen(false);
  };

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "bs";
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, []);

  const toggleLanguage = () => {
    const newLanguage = language === "bs" ? "en" : "bs";
    setLanguage(newLanguage);
    localStorage.setItem("language", newLanguage);
    window.location.reload();
  };

  const value = isScrolled || !isPocetna;

  const toggleNavbar = () => {
    setIsNavbarExpanded(!isNavbarExpanded);
  };

  // Function to navigate to profile page
  const navigateToProfile = () => {
    navigate("/profile"); // Adjust with your actual profile route
  };

  // Function to navigate to settings page
  const navigateToSettings = () => {
    navigate("/settings"); // Adjust with your actual settings route
  };

  const handleLogout = () => {
    logout();
    setShowToast(true);
  };

  return (
    <>
      <Navbar
        expand="lg"
        style={
          value
            ? { backgroundColor: "#051435" }
            : { backgroundColor: "transparent", backdropFilter: "blur(32px)" }
        }
        variant="dark"
        fixed="top"
        expanded={isNavbarExpanded}
        className="homepage__navbar"
      >
        <Container>
          <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
            <img
              src={Logo}
              width="50"
              height="50"
              className="d-inline-block align-top rounded-circle"
              alt="MEF logo"
            />
            <div className="ms-2">
              <p className="navbar__title">
                {translations[language].nazivUniverziteta}
              </p>
              <p className="navbar__title">
                {translations[language].nazivFakulteta}
              </p>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={mobileView ? toggleNavbar : null}
          />
          <Navbar.Collapse id="basic-navbar-nav">
            {/* Centered navigation links */}
            <Nav className="mx-auto">
              {/* Home Dropdown */}
              <Nav.Link
                as={Link}
                to="/"
                className="nav-link"
                onClick={mobileView ? toggleNavbar : null}
              >
                {translations[language].pocetna}
              </Nav.Link>

              {/* About Dropdown */}
              <div
                className="dropdown-wrapper about__page"
                onMouseEnter={handleFakultetMouseEnter}
                onMouseLeave={handleFakultetMouseLeave}
              >
                <NavDropdown
                  title={
                    <span>
                      {translations[language].fakultet}{" "}
                      <i className="bi bi-chevron-down ms-1"></i>
                      {/* Add Bootstrap chevron icon */}
                    </span>
                  }
                  id="fakultet-dropdown"
                  show={isFakultetDropdownOpen}
                  onClick={() =>
                    setIsFakultetDropdownOpen(!isFakultetDropdownOpen)
                  }
                >
                  <NavDropdown.Item
                    as={Link}
                    to="/fakultet"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <OnamaIcon />{" "}
                    </span>
                    {translations[language].oNama}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/fakultet/nastavno-osoblje"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <NastavnoOsobljeIcon />{" "}
                    </span>
                    {translations[language].nastavnoOsoblje}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/fakultet/dokumenti"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <DokumentiIcon />
                    </span>
                    {translations[language].dokumenti}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/fakultet-studentska"
                    onClick={mobileView ? toggleNavbar : null}
                    // disabled={true}
                  >
                    <span className="svg__wrapper">
                      <StudentskaSluzbaIcon />{" "}
                    </span>
                    {translations[language].studentskaSluzba}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/fakultet-javne-nabavke"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <JavneNabavkeIcon />
                    </span>
                    {translations[language].javneNabavke}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/fakultet-historija"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <HistorijaIcon />
                    </span>
                    {translations[language].historija}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/fakultet-kontakt"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <KontaktIcon />
                    </span>
                    {translations[language].kontakt}
                  </NavDropdown.Item>
                </NavDropdown>
              </div>

              {/* Aktuelnosti Dropdown */}
              <div
                className="dropdown-wrapper"
                onMouseEnter={handleAktuelnostiMouseEnter}
                onMouseLeave={handleAktuelnostiMouseLeave}
              >
                <NavDropdown
                  title={
                    <span>
                      {translations[language].aktuelnosti}{" "}
                      <i className="bi bi-chevron-down ms-1"></i>
                      {/* Add Bootstrap chevron icon */}
                    </span>
                  }
                  id="aktuelnosti-dropdown"
                  show={isAktuelnostiDropdownOpen}
                  onClick={() =>
                    setIsAktuelnostiDropdownOpen(!isAktuelnostiDropdownOpen)
                  }
                >
                  <NavDropdown.Item
                    as={Link}
                    to="/aktuelnosti/novosti"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <NovostiIcon />
                    </span>
                    {translations[language].novosti}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/aktuelnosti/obavijesti"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <ObavijestiIcon />
                    </span>
                    {translations[language].obavijesti}
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    as={Link}
                    to="/fakultet-kontakt"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <SeminariIcon />
                    </span>
                    {translations[language].seminari}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/fakultet-kontakt"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <KoneferencijeIcon />
                    </span>
                    {translations[language].konferencije}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/fakultet-kontakt"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <MasinijadaIcon />
                    </span>
                    {translations[language].masinijada}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/fakultet-kontakt"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <FondoviIcon />
                    </span>
                    {translations[language].fondovi}
                  </NavDropdown.Item>
                </NavDropdown>
              </div>

              {/* Nastava Dropdown */}
              <div
                className="dropdown-wrapper"
                onMouseEnter={handleNastavaMouseEnter}
                onMouseLeave={handleNastavaMouseLeave}
              >
                <NavDropdown
                  title={
                    <span>
                      {translations[language].nastava}{" "}
                      <i className="bi bi-chevron-down ms-1"></i>
                      {/* Add Bootstrap chevron icon */}
                    </span>
                  }
                  id="nastava-dropdown"
                  show={isNastavaDropdownOpen}
                  onClick={() =>
                    setIsNastavaDropdownOpen(!isNastavaDropdownOpen)
                  }
                >
                  <p className="navbar__subsection_title">
                    {translations[language].ciklusi}
                  </p>
                  <div
                    style={{ display: "flex", width: "100%", flexWrap: "wrap" }}
                  >
                    <NavDropdown.Item
                      as={Link}
                      to={
                        localStorage.getItem("language") === "en"
                          ? "/nastava/first-cycle-of-studies"
                          : "/nastava/prvi-ciklus-studija"
                      }
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <PrviCiklusIcon />
                      </span>
                      {translations[language].prviCiklus}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to={
                        localStorage.getItem("language") === "en"
                          ? "/nastava/second-cycle-of-studies"
                          : "/nastava/drugi-ciklus-studija"
                      }
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <DrugiCiklusIcon />
                      </span>
                      {translations[language].drugiCiklus}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to={
                        localStorage.getItem("language") === "en"
                          ? "/nastava/third-cycle-of-studies"
                          : "/nastava/treci-ciklus-studija"
                      }
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <TreciCiklusIcon />
                      </span>
                      {translations[language].treciCiklus}
                    </NavDropdown.Item>
                  </div>

                  <p className="navbar__subsection_title">
                    {translations[language].odsjeci}
                  </p>
                  <div
                    style={{ display: "flex", width: "100%", flexWrap: "wrap" }}
                  >
                    <NavDropdown.Item
                      as={Link}
                      to={
                        localStorage.getItem("language") === "en"
                          ? "/odsjeci/department-of-mathematics-and-physics"
                          : "/odsjeci/katedra-za-matematiku-i-fiziku"
                      }
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <KatedraFizikaMatematikaIcon />
                      </span>
                      {translations[language].fizikaMatematika}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to={
                        localStorage.getItem("language") === "en"
                          ? "/odsjeci/departments-of-solid-mechanics"
                          : "/odsjeci/katedra-za-mehaniku"
                      }
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <KatedraMehanikaIcon
                          style={{ width: "22px", height: "22px" }}
                        />
                      </span>
                      {translations[language].mehanika}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to={
                        localStorage.getItem("language") === "en"
                          ? "/odsjeci/department-of-energy-process-engineering-and-environmental-engineering"
                          : "/odsjeci/odsjek-za-energetiku-procesnu-tehniku-i-okolinsko-inzinjerstvo"
                      }
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <OdsjekEnergetikaProcesnatehnikaOkolinskoInzinjerstvoIcon />
                      </span>
                      <p style={{ margin: "0" }}>
                        {" "}
                        {translations[language].energetikaOkolinsko}
                      </p>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to={
                        localStorage.getItem("language") === "en"
                          ? "/odsjeci/department-of-industrial-engineering-and-management"
                          : "/odsjeci/odsjek-za-industrijsko-inzinjerstvo-i-menadzment"
                      }
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <OdsjekIndustrijskoInzinjerstvoMenadzmentIcon />
                      </span>
                      {translations[language].industrijskoMenadzment}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to={
                        localStorage.getItem("language") === "en"
                          ? "/odsjeci/department-of-mechanical-design"
                          : "/odsjeci/odsjek-za-masinske-konstrukcije"
                      }
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <OdsjekMasinskeKonstrukcijeIcon
                          style={{ width: "22px", height: "22px" }}
                        />
                      </span>
                      {translations[language].masinskeKonstrukcije}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to={
                        localStorage.getItem("language") === "en"
                          ? "/odsjeci/department-of-machinery-production-engineering"
                          : "/odsjeci/odsjek-za-masinski-proizvodni-inzinjering"
                      }
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <OdsjekMasinskiProizvodniInzinjeringIcon
                          style={{ width: "22px", height: "22px" }}
                        />
                      </span>
                      {translations[language].masinskiProizvodni}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to={
                        localStorage.getItem("language") === "en"
                          ? "/odsjeci/department-of-vehicle-engineering"
                          : "/odsjeci/odsjek-za-motore-i-vozila"
                      }
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <OdsjekMotoriVozilaIcon
                          style={{ width: "22px", height: "22px" }}
                        />
                      </span>
                      {translations[language].motoriVozila}
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      as={Link}
                      to={
                        localStorage.getItem("language") === "en"
                          ? "/odsjeci/department-of-defense-technology"
                          : "/odsjeci/odsjek-za-odbrambene-tehnologije"
                      }
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <OdsjekOdbrambenatehnologijaIcon />
                      </span>
                      {translations[language].odbrambenaTehnologija}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to={
                        localStorage.getItem("language") === "en"
                          ? "/odsjeci/department-of-wood-technology"
                          : "/odsjeci/odsjek-za-tehnologiju-drveta"
                      }
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <OdsjekTehnologijaDrvetaIcon />
                      </span>
                      {translations[language].tehnologijaDrveta}
                    </NavDropdown.Item>
                  </div>
                  <p className="navbar__subsection_title">
                    {translations[language].ostalo}
                  </p>
                  <div
                    style={{ display: "flex", width: "100%", flexWrap: "wrap" }}
                  >
                    <NavDropdown.Item
                      as={Link}
                      to="/fakultet/nastavno-osoblje"
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <NastavnoOsobljeIcon />
                      </span>
                      {translations[language].nastavnoOsoblje}
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      as="a"
                      href="https://nastava2.mef.unsa.ba/moodle/login/index.php"
                      target="_blank" // Opens link in a new tab
                      rel="noopener noreferrer" // Improves security
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <CoursewareIcon />
                      </span>
                      {translations[language].courseware}
                    </NavDropdown.Item>
                  </div>
                </NavDropdown>
              </div>

              {/* Studenti Dropdown */}
              <div
                className="dropdown-wrapper"
                onMouseEnter={handleStudentiMouseEnter}
                onMouseLeave={handleStudentiMouseLeave}
              >
                <NavDropdown
                  title={
                    <span>
                      {translations[language].studenti}{" "}
                      <i className="bi bi-chevron-down ms-1"></i>
                      {/* Add Bootstrap chevron icon */}
                    </span>
                  }
                  id="studenti-dropdown"
                  show={isStudentiDropdownOpen}
                  onClick={() =>
                    setIsStudentiDropdownOpen(!isStudentiDropdownOpen)
                  }
                >
                  <NavDropdown.Item
                    as={Link}
                    to="/fakultet-kontakt"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <RezultatiiIcon />
                    </span>
                    {translations[language].rezultatiIspita}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/fakultet-kontakt"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <StudentskaUnijaIcon />
                    </span>
                    {translations[language].studentskaUnija}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/fakultet-kontakt"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <BibliotekaIcon />
                    </span>
                    {translations[language].biblioteka}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/fakultet-kontakt"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <StudentskiBlogIcon />
                    </span>{" "}
                    {translations[language].studentskiBlog}
                  </NavDropdown.Item>
                </NavDropdown>
              </div>

              {/* Projekti Dropdown */}
              <div
                className="dropdown-wrapper"
                onMouseEnter={handleProjektiMouseEnter}
                onMouseLeave={handleProjektiMouseLeave}
              >
                <NavDropdown
                  title={
                    <span>
                      {translations[language].projekti}{" "}
                      <i className="bi bi-chevron-down ms-1"></i>
                      {/* Add Bootstrap chevron icon */}
                    </span>
                  }
                  id="projekti-dropdown"
                  show={isProjektiDropdownOpen}
                  onClick={() =>
                    setIsProjektiDropdownOpen(!isProjektiDropdownOpen)
                  }
                >
                  <div style={{ display: "flex", width: "100%" }}>
                    <NavDropdown.Item
                      as={Link}
                      to="/fakultet-kontakt"
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <OprojektimaIcon />
                      </span>
                      {translations[language].oProjektima}
                    </NavDropdown.Item>
                  </div>
                  <p className="navbar__subsection_title">
                    {translations[language].realizovaniProjekti}
                  </p>
                  <div
                    style={{ display: "flex", width: "100%", flexWrap: "wrap" }}
                  >
                    <NavDropdown.Item
                      as={Link}
                      to="/fakultet-kontakt"
                      onClick={mobileView ? toggleNavbar : null}
                    >
                      <span className="svg__wrapper">
                        <ProjektiRECDIIcon />
                      </span>
                      {translations[language].projektRECDI}
                    </NavDropdown.Item>
                    {/* <NavDropdown.Item
                    as={Link}
                    to="/projekti-link1"
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <IduciProjektiIcon />
                    </span>
                    {translations[language].iduciProjekti}
                  </NavDropdown.Item> */}
                  </div>
                </NavDropdown>
              </div>

              {/* Linkovi Dropdown */}
              <div
                className="dropdown-wrapper"
                onMouseEnter={handlePortaliMouseEnter}
                onMouseLeave={handlePortaliMouseLeave}
              >
                <NavDropdown
                  title={
                    <span>
                      {translations[language].linkovi}{" "}
                      <i className="bi bi-chevron-down ms-1"></i>
                      {/* Add Bootstrap chevron icon */}
                    </span>
                  }
                  id="portali-dropdown"
                  show={isPortaliDropdownOpen}
                  onClick={() =>
                    setIsPortaliDropdownOpen(!isPortaliDropdownOpen)
                  }
                  disabled={false}
                >
                  <NavDropdown.Item
                    as="a"
                    href="https://www.eunsa.ba/"
                    target="_blank" // Opens link in a new tab
                    rel="noopener noreferrer" // Improves security
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <EUNSAIcon />
                    </span>
                    {translations[language].eUNSA}
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    as="a"
                    href="https://biblioteka.mef.unsa.ba/default"
                    target="_blank" // Opens link in a new tab
                    rel="noopener noreferrer" // Improves security
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <EBibliotekaIcon />
                    </span>
                    {translations[language].eBiblioteka}
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    as="a"
                    href="https://webmail.mef.unsa.ba/"
                    target="_blank" // Opens link in a new tab
                    rel="noopener noreferrer" // Improves security
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <WebEmailIcon />
                    </span>
                    {translations[language].webMail}
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    as="a"
                    href="https://nastava2.mef.unsa.ba/moodle/login/index.php"
                    target="_blank" // Opens link in a new tab
                    rel="noopener noreferrer" // Improves security
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <CoursewareIcon />
                    </span>
                    {translations[language].courseware}
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    as="a"
                    href="https://www.linkedin.com/school/mef-unsa/posts/?feedView=all"
                    target="_blank" // Opens link in a new tab
                    rel="noopener noreferrer" // Improves security
                    onClick={mobileView ? toggleNavbar : null}
                  >
                    <span className="svg__wrapper">
                      <LinkedIncon />
                    </span>
                    LinkedIn
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            </Nav>

            {/* Icons on the right */}
            <Nav className="nav__icons">
              {/* Icons Wrapper */}
              {!isAuthenticated ? (
                <div
                  className="d-flex align-items-center style__icon"
                  onClick={handleShowModal}
                >
                  <Example width="24" height="24" />
                </div>
              ) : (
                // Bootstrap Dropdown for user options
                <Dropdown
                  className="user_dropdown"
                  style={{
                    marginRight: "10px",
                    width: "48px",
                  }}
                >
                  <Dropdown.Toggle
                    id="dropdown-custom-components"
                    className="style__icon user_background"
                  >
                    <img
                      src={localStorage.getItem("user_image") || userInitials}
                      alt="user_image"
                      className="user_image"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdown-menu user_menu">
                    <Dropdown.Item
                      onClick={navigateToProfile}
                      disabled={true}
                      style={{ margin: "5px" }}
                    >
                      {localStorage.getItem("language") === "en"
                        ? "Profile"
                        : "Profil"}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={navigateToSettings}
                      disabled={true}
                      style={{ margin: "5px" }}
                    >
                      {localStorage.getItem("language") === "en"
                        ? "Settings"
                        : "Postavke"}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={handleLogout}
                      style={{ margin: "5px" }}
                    >
                      {localStorage.getItem("language") === "en"
                        ? "Logout"
                        : "Odjava"}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
              {/* Language Selector Circle */}
              <div onClick={toggleLanguage} className="style__icon">
                {language === "en" ? "BS" : "EN"}
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <LoginModal showModal={showModal} handleClose={handleCloseModal} />
    </>
  );
};

export default MyNavbar;

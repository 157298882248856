import React from "react";
import styles from "./PrvaSekcija.module.css"; // Import the CSS module

const AdvantageSection = () => {
  const cardsData = [
    {
      title: "Atraktivan program",
      text: "Naši programi su prepoznati na domaćem i međunarodnom tržištu, a diplomanti se brzo zapošljavaju i napreduju.",
      title_en: "Attractive Program",
      text_en:
        "Our programs are recognized in the domestic and international market, and graduates quickly find employment and advance.",
    },
    {
      title: "Inovativno obrazovanje",
      text: "Studijski programi su prilagođeni modernim potrebama tržišta i industrijskim trendovima.",
      title_en: "Innovative Education",
      text_en:
        "Study programs are tailored to modern market needs and industry trends.",
    },
    {
      title: "Pristup savremenim resursima",
      text: "Studenti imaju pristup najsavremenijim laboratorijama i resursima za istraživanje.",
      title_en: "Access to Modern Resources",
      text_en:
        "Students have access to the most modern laboratories and research resources.",
    },
    {
      title: "Podrška karijernom razvoju",
      text: "Aktivna podrška u traženju prakse i zaposlenja nakon studija.",
      title_en: "Career Development Support",
      text_en:
        "Active support in finding internships and employment after studies.",
    },
    {
      title: "Povezanost sa industrijom",
      text: "Jaka povezanost sa lokalnom i međunarodnom industrijom kroz projekte i prakse.",
      title_en: "Industry Connection",
      text_en:
        "Strong connection with local and international industry through projects and internships.",
    },
    {
      title: "Raznovrsne mogućnosti istraživanja",
      text: "Mogućnost uključivanja u projekte i istraživanja tokom studija.",
      title_en: "Diverse Research Opportunities",
      text_en: "Opportunity to engage in projects and research during studies.",
    },
  ];

  return (
    <section className="container__wrapper bg-light" id="onama">
      <div className="container">
        <h2 className="section_title">
          {localStorage.getItem("language") === "en"
            ? "Core benefits of studying at the Faculty of Mechanical Engineering"
            : "Osnovne prednosti studiranja na Mašinskom fakultetu"}
        </h2>
        <p className="section_subtitle col-lg-10">
          {localStorage.getItem("language") === "en"
            ? "The Faculty of Mechanical Engineering provides students with education in key areas of mechanical engineering: production and industrial engineering, energy, process engineering, environmental engineering, mechanical design, engines and vehicles, and computer engineering. Advantages include:"
            : "Mašinski fakultet pruža studentima obrazovanje iz ključnih oblasti mašinskog inžinjerstva: proizvodni i industrijski inžinjering, energetika, procesna tehnika, okolišno inžinjerstvo, mašinske konstrukcije, motori i vozila, te računarsko inžinjerstvo. Prednosti uključuju:"}
        </p>

        <div className="row mt-5">
          {cardsData.map((card, index) => (
            <div
              className={`col-lg-4 col-md-6 col-sm-12 ${styles.cardColumn}`}
              key={index}
            >
              <div className={styles.cardCustom}>
                <h3 className="card__title">
                  {localStorage.getItem("language") === "en"
                    ? card.title_en
                    : card.title}
                </h3>
                <p className="card__subtitle">
                  {localStorage.getItem("language") === "en"
                    ? card.text_en
                    : card.text}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AdvantageSection;

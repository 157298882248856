import React from "react";
import NastavnoOsobljeList from "./NastavnoOsobljeList";
import MainBanner from "../../../components/Baner";
import { useState, useEffect } from "react";
import osobljeBanner from "../../../assets/nastavno-osoblje.jpg";

const NastavnoOsobljeComponent = () => {
  const [language, setLanguage] = useState("bs");

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, []);
  // Meta Tags
  document.title =
    language === "bs"
      ? "Nastavno osoblje | Mašinski fakultet Univerzitet u Sarajevu"
      : "Faculty Staff | Mechanical Engineering University of Sarajevo";
  document
    .querySelector('meta[name="description"]')
    .setAttribute(
      "content",
      "Nastavno osoblje-Mašinski fakultet Univerzitet u Sarajevu"
    );
  return (
    <>
      <MainBanner
        title={language === "bs" ? "Nastavno osoblje" : "Faculty staff"}
        bannerImage={osobljeBanner}
        description={
          language === "bs"
            ? "Nastavno osoblje Mašinskog fakulteta - Univerziteta u Sarajevu. Upoznaj tim koji oblikuje našu budućnost."
            : "Teaching staff of the Faculty of Mechanical Engineering - University of Sarajevo. Meet the team shaping our future."
        }
      />
      <NastavnoOsobljeList />
    </>
  );
};
export default NastavnoOsobljeComponent;

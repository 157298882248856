import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Logo from "../assets/mef_logo_white.png";
import {
  FaEnvelope,
  FaLinkedin,
  FaPhone,
  FaMapMarkerAlt,
} from "react-icons/fa";
import "../styles/global.css";
import style from "./Footer.module.css";

const translations = {
  bs: {
    nazivUniverziteta: "Univerzitet u Sarajevu",
    nazivFakulteta: "Mašinski fakultet",
    pocetna: "Početna",
    fakultet: "Fakultet",
    oNama: "O nama",
    kontaktInformacije: "Kontakt informacije",
    nastavnoOsoblje: "Nastavno osoblje",
    dokumenti: "Dokumenti",
    studentskaSluzba: "Studentska služba",
    javneNabavke: "Javne nabavke",
    historija: "Historija",
    kontakt: "Kontakt",
    aktuelnosti: "Aktuelnosti",
    obavijesti: "Obavijesti",
    novosti: "Novosti",
    seminari: "Seminari",
    konferencije: "Konferencije",
    masinijada: "Mašinijade",
    fondovi: "Fondovi",
    studenti: "Studentske novosti",
    rezultatiIspita: "Rezultati ispita",
    studentskaUnija: "Studentska unija",
    studentiHeading: "Studenti",
    biblioteka: "Biblioteka",
    studentskiBlog: "Studentski blog",
    nastava: "Nastava",
    ciklusi: "Ciklusi studija",
    prviCiklus: "Prvi ciklus",
    drugiCiklus: "Drugi ciklus",
    treciCiklus: "Treći ciklus",
    odsjeci: "Katedre i Odsjeci",
    fizikaMatematika: "Katedra za fiziku i matematika",
    mehanika: "Katedra za mehanika",
    masinskeKonstrukcije: "Odsjek za mašinske konstrukcije",
    masinskiProizvodni: "Odsjek za mašinski proizvodni inžinjering",
    industrijskoMenadzment: "Odsjek za industrijsko inžinjerstvo i menadžment",
    energetikaOkolinsko:
      "Odsjek za energetiku, procesnu tehniku i okolinsko inžinjerstvo",
    tehnologijaDrveta: "Odsjek za tehnologiju drveta",
    motoriVozila: "Odsjek za motore i vozila",
    odbrambeneTehnologija: "Odsjek za odbrambene tehnologije",
    ostalo: "Ostalo",
    onlineNastava: "Online nastava",
    courseware: "Courseware",
    projekti: "Projekti",
    oProjektima: "O projektima",
    realizovaniProjekti: "Realizovani projekti",
    projektRECDI: "Projekt RECDI",
    iduciProjekti: "Idući projekat",
    linkovi: "Linkovi",
    eUNSA: "eUNSA",
    eBiblioteka: "e-Biblioteka",
    webMail: "Web Mail",
    linkedin: "LinkedIn",
    login: "Prijava",
    fakultetClanicaUNSATekst:
      "Mašinski fakultet Sarajevo je članica JU Univerzitet u Sarajevu",
    ministarstvoObrazovanjaKS: "Ministarstvo obrazovanja KS",
    ministarstvoObrazovanjaFBiH: "Ministarstvo obrazovanja FBiH",
    prijavaKorupcije: "Prijava korupcije",
  },

  en: {
    nazivUniverziteta: "University of Sarajevo",
    nazivFakulteta: "Faculty of Mechanical Engineering",
    pocetna: "Home",
    fakultet: "Faculty",
    oNama: "About Us",
    nastavnoOsoblje: "Staff",
    kontakt: "Contact",
    dokumenti: "Documents",
    studentskaSluzba: "Student Services",
    javneNabavke: "Public Procurement",
    historija: "History",
    kontaktInformacije: "Contact Informations",
    aktuelnosti: "News",
    obavijesti: "Announcements",
    novosti: "News",
    seminari: "Seminars",
    konferencije: "Conferences",
    masinijada: "Masinijade",
    fondovi: "Funds",
    studenti: "Student News",
    rezultatiIspita: "Exam Results",
    studentiHeading: "Students",
    studentskaUnija: "Student Union",
    biblioteka: "Library",
    studentskiBlog: "Student Blog",
    nastava: "Study",
    ciklusi: "Study Cycles",
    prviCiklus: "First Cycle",
    drugiCiklus: "Second Cycle",
    treciCiklus: "Third Cycle",
    odsjeci: "Departments",
    fizikaMatematika: "Department Physics and Mathematics",
    mehanika: "Department of Solid Mechanics",
    masinskeKonstrukcije: "Department of Mechanical Design",
    masinskiProizvodni: "Department of Machinery Production Engineering",
    industrijskoMenadzment:
      "Department of Industrial Engineering and Management",
    energetikaOkolinsko:
      "Department of Energy, Process Engineering and Environmental Engineering",
    tehnologijaDrveta: "Department of Wood Technology",
    motoriVozila: "Department of Vehicle Engineering",
    odbrambeneTehnologija: "Department of Defense Technology",
    ostalo: "Other",
    onlineNastava: "Online Classes",
    courseware: "Courseware",
    projekti: "Projects",
    oProjektima: "About Projects",
    realizovaniProjekti: "Completed Projects",
    projektRECDI: "Project RECDI",
    iduciProjekti: "Next Project",
    linkovi: "Links",
    eUNSA: "eUNSA",
    eBiblioteka: "e-Library",
    webMail: "Web Mail",
    linkedin: "LinkedIn",
    login: "Login",
    fakultetClanicaUNSATekst:
      "The Faculty of Mechanical Engineering Sarajevo is a member of the Public Institution of the University of Sarajevo",
    ministarstvoObrazovanjaKS: "The Ministry of Education of the KS",
    ministarstvoObrazovanjaFBiH: "The Ministry of Education of the FBiH",
    prijavaKorupcije: "Report Corruption",
  },
};
const currentYear = new Date().getFullYear();

function getCurrentLanguage() {
  const language = localStorage.getItem("language");
  return language ? language : "bs";
}

function translate(key) {
  const currentLanguage = getCurrentLanguage();
  return translations[currentLanguage][key] || key;
}

const Footer = () => {
  const location = useLocation();
  const isMobile = window.innerWidth < 1000;
  const navigate = useNavigate();
  const language = localStorage.getItem("language");
  const showFooterNavigation =
    (location.pathname === "/" || location.pathname === "/en/") && !isMobile;

  return (
    <footer class="bg-transparent text-white py-5 pb-2" id="footer">
      <Container>
        {/* First Section */}
        <Row className="pb-4 mb-4 ">
          <Col md={5} xs={12} className="mb-4 mb-md-0">
            <div className="d-flex align-items-center">
              <img
                src={Logo}
                alt="Logo"
                class="me-3"
                style={{ width: "100px" }}
              />
              <div>
                <h5 class={style.universityTitle}>
                  {translations[language].nazivUniverziteta}
                </h5>
                <h5 class={style.universityTitle}>
                  {translations[language].nazivFakulteta}
                </h5>
              </div>
            </div>
            <p style={{ fontSize: "14px" }} className="mt-4 mb-1">
              {translations[language].fakultetClanicaUNSATekst}
            </p>
          </Col>
          <Col md={7} xs={12}>
            <h5 className="mb-4" class={style.sectionTitle}>
              {translations[language].kontaktInformacije}
            </h5>
            <Row>
              <Col xs={12} md={4} className="mb-2">
                <p class="fs-6">
                  <FaEnvelope /> info@mef.unsa.ba
                </p>
                <p
                  style={{ fontSize: "16px", cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/school/mef-unsa/posts/?feedView=all",
                      "_blank"
                    )
                  }
                >
                  <FaLinkedin /> LinkedIn
                </p>
              </Col>
              <Col xs={12} md={4} className="mb-2">
                <p class="fs-6">
                  <FaPhone /> +387 33 729 800
                </p>
                <p class="fs-6">
                  <FaPhone /> +387 33 653 055
                </p>
              </Col>
              <Col xs={12} md={4} className="mb-2">
                <p class="fs-6">
                  <FaMapMarkerAlt /> Vilsonovo šetalište 9
                </p>
                <p class="fs-6">
                  <FaMapMarkerAlt /> 71000 Sarajevo
                </p>
                <p class="fs-6">
                  <FaMapMarkerAlt /> Bosna i Hercegovina
                </p>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Second and Third Sections */}
        {showFooterNavigation ? (
          <div className="footer__navigation border-top border-bottom border-secondary pt-4 ">
            <Row className="">
              <Col xs={6} md={3} className="mb-3">
                <h5 class={style.sectionTitle}>{translate("fakultet")}</h5>
                <ul style={{ listStyle: "none", padding: 0 }}>
                  <li className="footer__link">
                    <Link to="/">{translate("pocetna")}</Link>
                  </li>

                  <li className="footer__link">
                    <Link to="/fakultet">{translate("oNama")}</Link>
                  </li>

                  <li className="footer__link">
                    <Link to="/fakultet/nastavno-osoblje">
                      {translate("nastavnoOsoblje")}
                    </Link>
                  </li>

                  <li className="footer__link">
                    <Link to="/fakultet/dokumenti">
                      {translate("dokumenti")}
                    </Link>
                  </li>

                  <li className="footer__link">
                    <Link to="/fakultet-kontakt">{translate("kontakt")}</Link>
                  </li>
                </ul>

                <h5 class={style.sectionTitle}>{translate("studentiHeading")}</h5>
                <ul style={{ listStyle: "none", padding: 0 }}>
                  <li className="footer__link">
                    <Link to="/fakultet-kontakt">
                      {translate("rezultatiIspita")}
                    </Link>
                  </li>
                  <li className="footer__link">
                    <Link to="/fakultet-kontakt">
                      {translate("studentskaSluzba")}
                    </Link>
                  </li>
                  <li className="footer__link">
                    <Link to="/fakultet-kontakt">
                      {translate("studentskaUnija")}
                    </Link>
                  </li>
                  <li className="footer__link">
                    <Link to="/fakultet-kontakt">
                      {translate("biblioteka")}
                    </Link>
                  </li>
                </ul>
              </Col>

              <Col xs={6} md={3} className="mb-3">
                <h5 class={style.sectionTitle}>{translate("ciklusi")}</h5>
                <ul style={{ listStyle: "none", padding: 0 }}>
                  <li className="footer__link">
                    <Link to="/nastava/prvi-ciklus-studija">
                      {translate("prviCiklus")}
                    </Link>
                  </li>

                  <li className="footer__link">
                    <Link to="/nastava/drugi-ciklus-studija">
                      {translate("drugiCiklus")}
                    </Link>
                  </li>

                  <li className="footer__link">
                    <Link to="/nastava/treci-ciklus-studija">
                      {translate("treciCiklus")}
                    </Link>
                  </li>
                </ul>
                <h5 class={style.sectionTitle}>{translate("aktuelnosti")}</h5>
                <ul style={{ listStyle: "none", padding: 0 }}>
                  <li className="footer__link">
                    <Link to="/aktuelnosti/obavijesti">
                      {translate("obavijesti")}
                    </Link>
                  </li>

                  <li className="footer__link">
                    <Link to="/aktuelnosti/novosti">
                      {translate("novosti")}
                    </Link>
                  </li>

                  <li className="footer__link">
                    <Link to="/fakultet-kontakt">{translate("seminari")}</Link>
                  </li>

                  <li className="footer__link">
                    {" "}
                    <Link to="/fakultet-kontakt">
                      {translate("konferencije")}
                    </Link>
                  </li>

                  <li className="footer__link">
                    {" "}
                    <Link to="/fakultet-kontakt">
                      {translate("masinijada")}
                    </Link>
                  </li>

                  <li className="footer__link">
                    {" "}
                    <Link to="/fakultet-kontakt">{translate("studenti")}</Link>
                  </li>

                  <li className="footer__link">
                    {" "}
                    <Link to="/fakultet-kontakt">{translate("fondovi")}</Link>
                  </li>
                </ul>
              </Col>

              <Col xs={6} md={3} className="mb-3">
                <h5 class={style.sectionTitle}>{translate("projekti")}</h5>
                <ul style={{ listStyle: "none", padding: 0 }}>
                  <li className="footer__link">
                    <Link to="/fakultet-kontakt">
                      {translate("oProjektima")}
                    </Link>
                  </li>
                  <li className="footer__link">
                    <Link to="/fakultet-kontakt">
                      {translate("projektRECDI")}
                    </Link>
                  </li>
                </ul>

                <h5 class={style.sectionTitle}>{translate("odsjeci")}</h5>
                <ul style={{ listStyle: "none", padding: 0 }}>
                  <li className="footer__link">
                    <Link to="/odsjeci/katedra-za-matematiku-i-fiziku">
                      {translate("fizikaMatematika")}
                    </Link>
                  </li>

                  <li className="footer__link">
                    <Link to="/odsjeci/katedra-za-mehaniku">
                      {translate("mehanika")}
                    </Link>
                  </li>

                  <li className="footer__link">
                    <Link to="/odsjeci/odsjek-za-energetiku-procesnu-tehniku-i-okolinsko-inzinjerstvo">
                      {translate("energetikaOkolinsko")}
                    </Link>
                  </li>

                  <li className="footer__link">
                    <Link to="/odsjeci/odsjek-za-industrijsko-inzinjerstvo-i-menadzment">
                      {translate("industrijskoMenadzment")}
                    </Link>
                  </li>

                  <li className="footer__link">
                    <Link to="/odsjeci/odsjek-za-masinske-konstrukcije">
                      {translate("masinskeKonstrukcije")}
                    </Link>
                  </li>

                  <li className="footer__link">
                    <Link to="/odsjeci/odsjek-za-masinski-proizvodni-inzinjering">
                      {translate("masinskiProizvodni")}
                    </Link>
                  </li>

                  <li className="footer__link">
                    <Link to="/odsjeci/odsjek-za-motore-i-vozila">
                      {translate("motoriVozila")}
                    </Link>
                  </li>

                  <li className="footer__link">
                    <Link to="/odsjeci/odsjek-za-odbrambene-tehnologije">
                      {translate("odbrambeneTehnologija")}
                    </Link>
                  </li>

                  <li className="footer__link">
                    <Link to="/odsjeci/odsjek-za-tehnologiju-drveta">
                      {translate("tehnologijaDrveta")}
                    </Link>
                  </li>
                </ul>
              </Col>

              <Col xs={6} md={3} className="mb-3">
                <h5 class={style.sectionTitle}>{translate("linkovi")}</h5>
                <ul style={{ listStyle: "none", padding: 0 }}>
                  <li className="footer__link">
                    <a
                      href="https://www.eunsa.ba/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      eUNSA
                    </a>
                  </li>

                  <li className="footer__link">
                    <a
                      href="https://biblioteka.mef.unsa.ba/default"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      e-Biblioteka
                    </a>
                  </li>

                  <li className="footer__link">
                    <a
                      href="https://webmail.mef.unsa.ba/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Web Mail
                    </a>
                  </li>

                  <li className="footer__link">
                    <a
                      href="https://nastava2.mef.unsa.ba/moodle/login/index.php"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Courseware
                    </a>
                  </li>
                </ul>

                <h5 class={style.sectionTitle}>{translate("ostalo")}</h5>
                <ul style={{ listStyle: "none", padding: 0 }}>
                  <li className="footer__link">
                    <a
                      href="https://unsa.ba/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {translate("nazivUniverziteta")}
                    </a>
                  </li>

                  <li className="footer__link">
                    <a
                      href="https://mo.ks.gov.ba/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {translate("ministarstvoObrazovanjaKS")}
                    </a>
                  </li>

                  <li className="footer__link">
                    <a
                      href="http://www.fmon.gov.ba/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {translate("ministarstvoObrazovanjaFBiH")}
                    </a>
                  </li>

                  <li className="footer__link">
                    <a
                      href="https://www.anticorrupiks.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {translate("prijavaKorupcije")}
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
            {/* Second and Third Sections */}
          </div>
        ) : (
          <div
            className="container border-bottom border-top border-secondary py-4 mb-3"
            style={{ borderColor: "#DDDDDD" }}
          >
            <div className="row d-flex justify-content-between align-items-center">
              <div className="col-12 col-md-auto mb-2 mb-md-0">
                <Link
                  to="/"
                  className="text-decoration-none text-white footer__link btn-link"
                >
                  {translate("pocetna")}
                </Link>
              </div>

              <div className="col-12 col-md-auto mb-2 mb-md-0">
                <Link
                  to="/fakultet"
                  className={`${style.footerLinkStyle} text-decoration-none text-white footer__link btn-link`}
                >
                  {translate("oNama")}
                </Link>
              </div>

              <div className="col-12 col-md-auto mb-2 mb-md-0">
                <Link
                  to="/aktuelnosti/novosti"
                  className={`${style.footerLinkStyle} text-decoration-none text-white footer__link btn-link`}
                >
                  {translate("novosti")}
                </Link>
              </div>

              <div className="col-12 col-md-auto mb-2 mb-md-0">
                <Link
                  to="/aktuelnosti/obavijesti"
                  className={`${style.footerLinkStyle} text-decoration-none text-white footer__link btn-link`}
                >
                  {translate("obavijesti")}
                </Link>
              </div>

              <div className="col-12 col-md-auto mb-2 mb-md-0">
                <Link
                  to="/fakultet/nastavno-osoblje"
                  className={`${style.footerLinkStyle} text-decoration-none text-white footer__link btn-link`}
                >
                  {translate("nastavnoOsoblje")}
                </Link>
              </div>

              <div className="col-12 col-md-auto mb-2 mb-md-0">
                <Link
                  to="/fakultet/dokumenti"
                  className={`${style.footerLinkStyle} text-decoration-none text-white footer__link btn-link`}
                >
                  {translate("dokumenti")}
                </Link>
              </div>
            </div>
          </div>
        )}
      </Container>
      <p className={`${style.rightsText} text-center mt-4`}>
        © {currentYear} MEF All rights reserved
      </p>
    </footer>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import style from "./PocetnaBaner.module.css";
import backgroundVideoDesktop from "../../assets/video/MEF_video_destkop.mp4"; // Import the video file
import backgroundVideoMobile from "../../assets/video/MEF_video_mobile.mp4";

const WelcomeBanner = () => {
  const [language, setLanguage] = useState("bs"); // Default to Bosnian (BS)
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  // Update view on window resize
  useEffect(() => {
    const handleResize = () => setIsDesktop(window.innerWidth > 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, []);

  const content = {
    bs: {
      heading: "Dobro došli na Mašinski fakultet",
      subheading:
        "Ekspertiza, znanje i iskustvo. Akademska izvrsnost u tehničkoj edukaciji.",
    },
    en: {
      heading: "Welcome to the Faculty of Mechanical Engineering",
      subheading:
        "Expertise, knowledge, and experience. Academic excellence in technical education.",
    },
  };

  return (
    <div class={style.bannerStyle}>
      {window.navigator.connection &&
      window.navigator.connection.effectiveType === "3g" ? null : (
        <video class={style.videoStyle} autoPlay loop muted playsInline>
          <source
            src={isDesktop ? backgroundVideoDesktop : backgroundVideoMobile}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      )}
      <div className="container" style={{ zIndex: 1 }}>
        <h1 className="heading-subtitle">{content[language].heading}</h1>
        <p className="heading-1">{content[language].subheading}</p>
      </div>
    </div>
  );
};

export default WelcomeBanner;

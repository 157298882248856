import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ButtonComponent from "../../components/ButtonComponent";
import Logo from "../../assets/mef-slike/o-nama-sekcija.jpg";
import { FaCheckCircle } from "react-icons/fa"; // For the checkmark icon
import BlueBannerComponent from "../../components/PlaviBaner";
import style from "./Onama.module.css";

const AboutSection = () => {
  const features = [
    {
      id: 1,
      title: "Profesionalna izvrsnost",
      titleEng: "Professional excellence",
    },
    {
      id: 2,
      title: "Radost učenja i fleksibilnost",
      titleEng: "Joy of learning and flexibility",
    },
    {
      id: 3,
      title: "Autonomija i stabilnost",
      titleEng: "Autonomy and stability",
    },
    {
      id: 4,
      title: "Posvećenost strategiji",
      titleEng: "Commitment to strategy",
    },
    {
      id: 5,
      title: "Preduzetništvo i inovativnost",
      titleEng: "Entrepreneurship and innovation",
    },
  ];
  return (
    <section className={`${style.sectionStyle} container__wrapper`} id="onama">
      <div className="container">
        <div className="row align-items-center">
          {/* Image Column */}
          <div className="col-lg-6 mb-4 mb-lg-0">
            <img
              src={Logo}
              alt="Masinski Fakultet"
              class={`${style.imageStyle} img-fluid`}
            />
          </div>

          {/* Text Column */}
          <div className="col-lg-6">
            <h2 class={style.titleStyle}>
              {localStorage.getItem("language") === "en"
                ? "Mission, vision and values"
                : "Misija, vizija i vrijednosti"}
            </h2>
            <p class={style.paragraphStyle}>
              {localStorage.getItem("language") === "en"
                ? `
                The Faculty of Mechanical Engineering in Sarajevo educates professionals ready to face the challenges of the modern economy through quality teaching and research, contributing to Bosnia and Herzegovina and beyond. We stay connected with our students through lifelong learning, guiding them toward the highest professional standards. Our mission is to become the leading higher education institution in technical sciences in Southeast Europe by 2025.`
                : `Mašinski fakultet Sarajevo obrazuje stručnjake spremne za izazove moderne privrede kroz kvalitetnu nastavu i istraživački rad, doprinos Bosni i Hercegovini i šire. Ostajemo povezani sa studentima kroz cjeloživotno učenje, vodeći ih prema najvišim profesionalnim standardima. Naša misija je do 2025. godine, postati vodeća visokoškolska institucija u tehničkim naukama u Jugoistočnoj Evropi.`}
            </p>
            <ul className="list-unstyled border-bottom">
              {features.map((feature, index) => (
                <li
                  key={index}
                  className="d-flex align-items-center py-2 border-bottom"
                  style={{ borderColor: "#DDDDDD" }}
                >
                  <span className="me-2">
                    <FaCheckCircle color="#0094FF" borderRadius="50%" />
                  </span>
                  <span style={{ fontSize: "16px" }}>
                    {localStorage.getItem("language") === "bs"
                      ? feature.title
                      : feature.titleEng}
                  </span>
                </li>
              ))}
            </ul>

            <ButtonComponent
              label={
                localStorage.getItem("language") === "bs"
                  ? "Historija fakulteta →"
                  : "History of the faculty →"
              }
              color="#000"
              backgroundColor="#9FDAF8"
              disabled={true}
            />
          </div>
        </div>
      </div>

      {/* Banner Section */}
      <BlueBannerComponent />
    </section>
  );
};

export default AboutSection;

import React, { useRef, useState, useEffect } from "react";
import Onama from "./Fakultet/Onama";
// import MainBanner from "./Fakultet/MainBanner";
import DekanatCards from "./Fakultet/DekanatKartice";
import DekanatStatements from "./Fakultet/DekanatIzjave";
import FacultyCycles from "./Fakultet/Ciklusi";
import FacultyDepartments from "./Fakultet/Odsjeci";
import SectionNavbar from "../components/NavigacijaSekcije";
import MainBanner from "../components/Baner";
import oNamaBanner from "../assets/o_nama-2.jpg";

function Fakultet() {
  const [isSticky, setIsSticky] = useState(false); // State to track stickiness
  const bannerRef = useRef(null);

  const items = [
    { name: "O nama", nameEng: "About us", link: "#onama" },
    {
      name: "Riječ dekana i prodekana",
      nameEng: "Dean's words",
      link: "#dekanat-statements",
    },
    {
      name: "Menadžment fakulteta",
      nameEng: "Faculty management",
      link: "#dekanat-cards",
    },
    { name: "Ciklusi", nameEng: "Cycles", link: "#faculty-cycles" },
    { name: "Odsjeci", nameEng: "Departments", link: "#faculty-departments" },
    // { name: "Galerija", nameEng: "Gallery", link: "#galerija" },
  ];
  // Meta Tags
  const language = localStorage.getItem("language");
  document.title =
    language === "bs"
      ? "O Fakultetu | Mašinski fakultet Univerzitet u Sarajevu"
      : "About Faculty | Mechanical Engineering University of Sarajevo";
  document
    .querySelector('meta[name="description"]')
    .setAttribute(
      "content",
      "O Fakultetu - Mašinski fakultet Univerziteta u Sarajevu"
    );
  return (
    <>
      <div ref={bannerRef}>
        <MainBanner
          showTwo={true}
          title={
            localStorage.getItem("language") === "en"
              ? "Expertise in technical"
              : "Izvrsnost u tehničkom"
          }
          titleSecond={
            localStorage.getItem("language") === "en"
              ? "Education"
              : "obrazovanju"
          }
          firstParagraph={
            localStorage.getItem("language") === "en"
              ? "Founded in 1958, the Faculty of Mechanical Engineering in Sarajevo has been leading education in the field of mechanical engineering for over 60 years. As part of the University of Sarajevo, it is one of the most respected academic institutions in Bosnia and Herzegovina, known for quality education and collaboration with universities from the region and the EU."
              : "Osnovan 1958. godine, Mašinski fakultet u Sarajevu već preko 60 godina predvodi obrazovanje u oblasti mašinstva. Kao dio Univerziteta u Sarajevu, jedan je od najcjenjenijih akademskih ustanova u Bosni i Hercegovini, poznat po kvalitetnom obrazovanju i saradnji s univerzitetima iz regije i EU."
          }
          secondParagraph={
            localStorage.getItem("language") === "en"
              ? "Through a comprehensive and interdisciplinary program, we provide students with theoretical knowledge and practical skills. Our students participate in research, development, and collaborations with leading universities, institutes, and companies, contributing to both the local and global community."
              : " Kroz sveobuhvatan i interdisciplinaran program, studentima pružamo teoretska znanja i praktične vještine. Naši studenti učestvuju u istraživanjima, razvoju i saradnjama s vodećim univerzitetima, institutima i firmama, doprinoseći lokalnoj i globalnoj zajednici."
          }
          bannerImage={oNamaBanner}
        />
      </div>
      <SectionNavbar items={items} observeRef={bannerRef} />
      <div id="onama">
        <Onama />
      </div>
      <div id="dekanat-statements">
        <DekanatStatements />
      </div>
      <div id="dekanat-cards">
        <DekanatCards />
      </div>
      <div id="faculty-cycles">
        <FacultyCycles />
      </div>
      <div id="faculty-departments">
        <FacultyDepartments />
      </div>
    </>
  );
}

export default Fakultet;

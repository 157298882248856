import React from "react";
import "./AnimacijaLinije.css";
import style from "./PlaviBaner.module.css";

const BlueBannerComponent = ({ title, text, button }) => {
  return (
    <div className={`container ${style.bannerStyle} mt-4`}>
      <div
        className="row d-flex justify-content-space-around w-100"
        style={{ backgroundColor: "transparent" }}
      >
        <div className="col-md-4 col-12 mb-3 mb-md-0">
          <div class={style.statItemStyle}>
            <div class={style.statNumberStyle}>836</div>
            <div class={style.statTextStyle}>
              {localStorage.getItem("language") === "en"
                ? "Active students"
                : "Aktivnih studenata"}
            </div>
          </div>
        </div>
        <div className="col-md-4 col-12 mb-3 mb-md-0">
          <div class={style.statItemStyle}>
            <div class={style.statNumberStyle}>259</div>
            <div class={style.statTextStyle}>
              {localStorage.getItem("language") === "en"
                ? "Courses in 2024/2025"
                : "Kurseva u 2024/2025"}
            </div>
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div class={style.statItemStyle}>
            <div class={style.statNumberStyle}>25</div>
            <div class={style.statTextStyle}>
              {localStorage.getItem("language") === "en"
                ? "Study programs"
                : "Studijskih programa"}
            </div>
          </div>
        </div>
      </div>
      <div className="line" style={{ left: "20%", animationDelay: "0s" }}></div>
      <div className="line" style={{ left: "50%", animationDelay: "1s" }}></div>
      <div className="line" style={{ left: "80%", animationDelay: "2s" }}></div>
    </div>
  );
};

export default BlueBannerComponent;

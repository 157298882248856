import React, { useState, useEffect } from "react";
import {
  FaArrowLeft,
  FaEnvelope,
  FaPhone,
  FaBuilding,
  FaLinkedin,
} from "react-icons/fa";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../styles/global.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import style from "./NastavnoOsobljeDetail.module.css";

import { FaGoogle, FaResearchgate } from "react-icons/fa";
import { fetchProfesoriDetail } from "../../../services/apiService";

const ProfessorDetail = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [professor, setProfessor] = useState(null);

  useEffect(() => {
    const fetchProfessor = async () => {
      try {
        const data = await fetchProfesoriDetail(slug);
        setProfessor(data);
      } catch (error) {
        navigate("/not-found", { replace: true });
        console.error("Error fetching professor:", error);
      }
    };

    fetchProfessor();
  }, [slug]);

  if (!professor) {
    return <div>Loading...</div>;
  }

  document.title =
    localStorage.getItem("language") === "bs"
      ? `${professor.short_rank}${professor.user.first_name} ${professor.user.last_name} | Mašinski fakultet Univerzitet u Sarajevu`
      : `${professor.short_rank}${professor.user.first_name} ${professor.user.last_name} | Mechanical Engineering University of Sarajevo`;

  document
    .querySelector('meta[name="description"]')
    .setAttribute(
      "content",
      `Nastavno osoblje detaljno - Mašinski fakultet Univerziteta u Sarajevu`
    );

  return (
    <div className="bg-white container__wrapper" style={{ marginTop: "50px" }}>
      <Container>
        {/* Back button */}
        <div onClick={() => navigate(-1)} class={style.backButton}>
          <FaArrowLeft className="me-2" />{" "}
          {localStorage.getItem("language") === "bs" ? "Nazad" : "Back"}
        </div>

        <Row style={{ display: "flex", justifyContent: "space-around" }}>
          {/* Professor Information Section */}
          <Col
            xs={12}
            md={4}
            lg={4}
            className={style.contentWrapper}
            style={{ marginBottom: "1.5rem" }}
          >
            <div className="d-flex  mb-3">
              <img
                className="professor-image avatar me-3"
                src={professor.image}
                alt={`${professor.user.first_name} ${professor.user.last_name}`}
              />
              <div>
                <p style={{ fontSize: "18px", fontWeight: "500", margin: "0" }}>
                  {professor.short_rank} {professor.user.first_name}{" "}
                  {professor.user.last_name}
                </p>
                <p
                  className="text-muted"
                  style={{
                    fontSize: "12px",
                    marginBottom: "0",
                  }}
                >
                  {professor.full_rank}
                </p>
              </div>
            </div>

            <hr style={{ borderColor: "#DDDDDD" }} />

            {/* Contact Information */}
            <div className="mt-3">
              <div className="mb-2 d-flex align-items-center">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip> Email</Tooltip>}
                >
                  <div target="_blank" style={{ display: "inline-block" }}>
                    <FaEnvelope className="me-2" />
                  </div>
                </OverlayTrigger>
                <a
                  href={`mailto:${professor.user.email}`}
                  style={{ color: "black" }}
                >
                  {professor.user.email}
                </a>
              </div>

              <div className="mb-2 d-flex align-items-center">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      {localStorage.getItem("language") === "en"
                        ? "Phone"
                        : "Telefon"}
                    </Tooltip>
                  }
                >
                  <div target="_blank" style={{ display: "inline-block" }}>
                    <FaPhone className="me-2" />
                  </div>
                </OverlayTrigger>

                {professor.phone || "N/A"}
              </div>

              <div className="mb-2 d-flex align-items-center">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      {localStorage.getItem("language") === "en"
                        ? "Office"
                        : "Kabinet"}
                    </Tooltip>
                  }
                >
                  <div target="_blank" style={{ display: "inline-block" }}>
                    <FaBuilding className="me-2" />
                  </div>
                </OverlayTrigger>
                {professor.office || "N/A"}
              </div>
            </div>

            <hr class={style.borderBottom} />

            {/* Consultation and Social Links */}
            <div>
              <div className="mb-2">
                <strong>
                  {localStorage.getItem("language") === "bs"
                    ? "Konsultacije:"
                    : "Consultation:"}
                </strong>{" "}
                {professor.consultation || "N/A"}
              </div>
              <hr class={style.borderBottom} />
              <div className="d-flex">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>LinkedIn</Tooltip>}
                >
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      window.open(professor.linkedin, "_blank");
                    }}
                    className="me-2"
                    style={{ cursor: "pointer" }}
                  >
                    <FaLinkedin style={{ color: "black" }} />
                  </div>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>ResearchGate</Tooltip>}
                >
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      window.open(professor.research_gate, "_blank");
                    }}
                    className="me-2"
                    style={{ cursor: "pointer" }}
                  >
                    <FaResearchgate style={{ color: "black" }} />
                  </div>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Google Scholar</Tooltip>}
                >
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      window.open(professor.google_scholar, "_blank");
                    }}
                    className="me-2"
                    style={{ cursor: "pointer" }}
                  >
                    <FaGoogle style={{ color: "black" }} />
                  </div>
                </OverlayTrigger>
              </div>
            </div>
          </Col>

          {/* Tabbed Section for Curriculum Vitae and Knjige */}
          <Col xs={12} md={7} className={style.contentWrapper}>
            <Tabs
              defaultActiveKey="cv"
              id="professor-details-tabs"
              className="mb-3"
            >
              <Tab eventKey="cv" title="Curriculum Vitae">
                <h5 class={style.borderBottom}>Curriculum Vitae</h5>

                <div
                  dangerouslySetInnerHTML={{
                    __html: professor.cv || "<p>N/A</p>",
                  }}
                  class={style.htmlWrapper}
                />
              </Tab>
              <Tab
                eventKey="knjige"
                title={
                  localStorage.getItem("language") === "en" ? "Books" : "Knjige"
                }
                style={{ padding: "10px" }}
              >
                <h5>
                  {localStorage.getItem("language") === "en"
                    ? "Books"
                    : "Knjige"}
                </h5>
                <hr class={style.borderBottom} />

                <p>N/A</p>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProfessorDetail;

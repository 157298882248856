// router.js
import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { getLocalizedPath } from "./helpers/helpers";

import NavbarComponent from "./components/NavigacijaStranice";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import DepartmentDetail from "./pages/Nastava/Departments/OdsjekDetaljno";
import NotFound from "./components/NotFound";
import Spinner from "./components/Spinner";

import Pocetna from "./pages/Pocetna";
import FakultetPage from "./pages/Fakultet";

import NovostiPage from "./pages/Aktuelnosti";
import NewsDetail from "./pages/Aktuelnosti/Novosti/NovostiDetaljno";
import ObavijestiComponent from "./pages/Aktuelnosti/Obavijesti/ObavijestiComponent";
import ObavijestDetail from "./pages/Aktuelnosti/Obavijesti/ObavijestDetail";
import NastavnoOsobljeComponent from "./pages/Fakultet/Nastavno osoblje/NastavnoOsoblje";
import NastavnoOsobljeDetail from "./pages/Fakultet/Nastavno osoblje/NastavnoOsobljeDetail";
import DocumentComponent from "./pages/Fakultet/Dokumenti/Dokumenti";
import CurriculumDetail from "./pages/Nastava/Curiculum/CiklusDetaljno";
import CommingSoon from "./components/CommingSoon";

const LanguageRedirect = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [redirecting, setRedirecting] = useState(false);

  useEffect(() => {
    const language = localStorage.getItem("language") || "bs";

    if (language === "en" && !location.pathname.startsWith("/en")) {
      setRedirecting(true);
      navigate(`/en${location.pathname}`, { replace: true });
    } else if (language !== "en" && location.pathname.startsWith("/en")) {
      setRedirecting(true);
      navigate(location.pathname.replace(/^\/en/, ""), { replace: true });
    } else {
      setRedirecting(false);
    }
  }, [location, navigate]);

  if (redirecting) {
    return <Spinner />;
  }

  return children;
};

function AppRouter({ handleShowModal }) {
  return (
    <>
      <NavbarComponent handleShowModal={handleShowModal} />
      <ScrollToTop />
      <LanguageRedirect>
        <Routes>
          {/* Home page route */}
          <Route path={getLocalizedPath("/")} element={<Pocetna />} />

          {/* Fakultet routes */}
          <Route
            path={getLocalizedPath("/fakultet")}
            element={<FakultetPage />}
          />
          <Route
            path={getLocalizedPath("/fakultet/nastavno-osoblje")}
            element={<NastavnoOsobljeComponent />}
          />
          <Route
            path={getLocalizedPath("/fakultet/nastavno-osoblje/:slug")}
            element={<NastavnoOsobljeDetail />}
          />
          <Route
            path={getLocalizedPath("/fakultet/dokumenti")}
            element={<DocumentComponent />}
          />

          {/* <Route
          path="/fakultet-nastavno-osoblje"
          element={<NastavnoOsobljePage />}
        /> */}
          <Route
            path={getLocalizedPath("/fakultet-studentska")}
            element={<CommingSoon />}
          />
          <Route
            path={getLocalizedPath("/fakultet-javne-nabavke")}
            element={<CommingSoon />}
          />
          <Route
            path={getLocalizedPath("/fakultet-historija")}
            element={<CommingSoon />}
          />
          <Route
            path={getLocalizedPath("/fakultet-kontakt")}
            element={<CommingSoon />}
          />
          {/* <Route
            path={getLocalizedPath("/commingsoonpage")}
            element={<CommingSoon />}
          /> */}

          {/* Aktuelnosti routes */}
          {/* <Route path="/aktuelnosti/obavijesti" element={<ObavijestiPage />} /> */}
          <Route
            path={getLocalizedPath("/aktuelnosti/novosti")}
            element={<NovostiPage />}
          />
          <Route
            path={getLocalizedPath("/aktuelnosti/novosti/:slug")}
            element={<NewsDetail />}
          />

          <Route
            path={getLocalizedPath("/aktuelnosti/obavijesti")}
            element={<ObavijestiComponent />}
          />
          <Route
            path={getLocalizedPath("/aktuelnosti/obavijesti/:slug")}
            element={<ObavijestDetail />}
          />
          {/* <Route path="/aktuelnosti/seminari" element={<SeminariPage />} />
        <Route
          path="/aktuelnosti/konferencije"
          element={<KonferencijePage />}
        /> */}
          {/* <Route path="/aktuelnosti/masinijada" element={<MasinijadaPage />} />
        <Route path="/aktuelnosti/fondovi" element={<FondoviPage />} /> */}

          {/* Studenti routes */}
          {/* <Route path="/studenti-link1" element={<StudentLink1 />} /> */}
          {/* Add more routes as needed */}

          {/* Projekti routes */}
          {/* <Route path="/projekti-link1" element={<ProjectLink1 />} /> */}
          {/* Add more routes as needed */}

          {/* Portali routes */}
          {/* /<Route path="/portali-link1" element={<PortalLink1 />} /> */}
          {/* Add more routes as needed */}
          <Route path="*" element={<NotFound />} />
          <Route
            path={getLocalizedPath("/odsjeci/:slug")}
            element={<DepartmentDetail />}
          />
          <Route
            path={getLocalizedPath("/nastava/:slug")}
            element={<CurriculumDetail />}
          />
        </Routes>
        <Footer />
      </LanguageRedirect>
    </>
  );
}

export default AppRouter;

import React from "react";
import {
  FaSearch,
  FaEnvelope,
  FaPhone,
  FaBuilding,
  FaRegCalendarAlt,
  FaLinkedin,
} from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from "react";
import { Pagination } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import "../../../styles/global.css";
import stlye from "./NastavnoOsobljeList.module.css";
import { FaGoogle, FaResearchgate, FaInfoCircle } from "react-icons/fa";
import { fetchAllOsoblje } from "../../../services/apiService";

const NastavnoOsobljeList = () => {
  const [nastavnoOsoblje, setNastavnoOsoblje] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  const [nastavnoOsobljeNum, setNastavnoOsobljeNum] = useState(0);
  const [selectedRank, setSelectedRank] = useState("");
  const [filterName, setFilterName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const itemsPerPage = 6;

  const navigate = useNavigate();
  const location = useLocation();

  // Extract the current page from the query parameter
  const queryParams = new URLSearchParams(location.search);
  const currentPage = parseInt(queryParams.get("page")) || 1;

  const totalPages = Math.ceil(nastavnoOsobljeNum / itemsPerPage);
  const visiblePageCount = 3;

  // Define an async function to fetch data
  const fetchData = async (page = 1, rank = "") => {
    setIsLoading(true);
    try {
      const data = await fetchAllOsoblje(page, rank, filterName);

      setNastavnoOsobljeNum(data.count);
      setNastavnoOsoblje(data.results); // Update state with fetched data
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  useEffect(() => {
    fetchData(currentPage, selectedRank, filterName);
  }, [currentPage, selectedRank, filterName]);

  const handleRankChange = (event) => {
    setSelectedRank(event.target.value);
    navigate("?page=1");
  };
  const handlePageChange = (page) => {
    navigate(`?page=${page}`);
    window.scrollTo(0, 0);
  };

  const handleTitleFilterChange = (e) => {
    setFilterName(e.target.value);
    navigate("?page=1"); // Reset to page 1
  };
  const getPaginationRange = () => {
    let start = Math.max(1, currentPage - 1);
    let end = Math.min(totalPages, start + visiblePageCount - 1);

    // Adjust start if we’re near the last page
    if (end === totalPages) {
      start = Math.max(1, totalPages - visiblePageCount + 1);
    }

    return [...Array(end - start + 1).keys()].map((n) => start + n);
  };

  return (
    <div className="mt-4 bg-white">
      <div className="container py-5">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center border-bottom pb-3 mb-4">
          <div className="col-12 col-md-4 mb-3">
            <select
              className="form-select"
              style={{ width: "100%" }} // Make the select take full width
              value={selectedRank}
              onChange={handleRankChange}
            >
              <option value="">
                {localStorage.getItem("language") === "en"
                  ? "All Staff"
                  : "Nastavno osoblje"}
              </option>
              <option value="redovni profesor">
                {localStorage.getItem("language") === "en"
                  ? "Professors"
                  : "Redovni profesori"}
              </option>
              <option value="vanredni profesor">
                {localStorage.getItem("language") === "en"
                  ? "Associate Professors"
                  : "Vanredni profesori"}
              </option>
              <option value="docent">
                {localStorage.getItem("language") === "en"
                  ? "Assistant Professors"
                  : "Docenti"}
              </option>
              <option value="visi asistent">
                {localStorage.getItem("language") === "en"
                  ? "Senior Assistants"
                  : "Viši asistenti"}
              </option>
              <option value="asistent">
                {localStorage.getItem("language") === "en"
                  ? "Assistants"
                  : "Asistenti"}
              </option>
            </select>
          </div>

          <div className="col-12 col-md-4">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder={
                  localStorage.getItem("language") === "en"
                    ? "Search"
                    : "Pretraži"
                }
                onChange={handleTitleFilterChange}
              />
            </div>
          </div>
        </div>
        {/* Show loading spinner if data is being fetched */}
        {isLoading ? (
          <div className="d-flex justify-content-center my-5">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <div className="row">
            {nastavnoOsoblje.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <p>
                  {localStorage.getItem("language") === "bs"
                    ? "Nema podataka"
                    : "No data"}
                </p>
              </div>
            ) : (
              nastavnoOsoblje.map((professor) => (
                <div className="col-md-6 mb-4" key={professor.user.email}>
                  <div className="card p-4">
                    <Link
                      to={`/fakultet/nastavno-osoblje/${professor.slug}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <div className="flex-grow-1">
                        <div className="d-flex align-items-center">
                          <img
                            className="professor-image avatar me-3"
                            src={professor.image}
                            alt={`${professor.user.first_name} ${professor.user.last_name}`}
                          />

                          <h5 className="card-title">
                            {professor.short_rank} {professor.user.first_name}{" "}
                            {professor.user.last_name}
                          </h5>
                          <FaInfoCircle class={stlye.infoIcon} />
                        </div>
                        <hr />

                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip> Email</Tooltip>}
                        >
                          <div
                            target="_blank"
                            style={{ display: "inline-block" }}
                          >
                            <FaEnvelope className="me-2" />
                          </div>
                          
                        </OverlayTrigger>
                        {professor.user.email}
                        <div className="mb-2">
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>{localStorage.getItem("language") === "en"
                              ? "Phone"
                              : "Telefon"}</Tooltip>}
                          >
                            <div
                              target="_blank"
                              style={{ display: "inline-block" }}
                            >
                              <FaPhone className="me-2" />
                            </div>
                          </OverlayTrigger>

                          {professor.phone}
                        </div>
                        <div className="mb-2">
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>{localStorage.getItem("language") === "en"
                              ? "Office"
                              : "Kabinet"}</Tooltip>}
                          >
                            <div
                              target="_blank"
                              style={{ display: "inline-block" }}
                            >
                              <FaBuilding className="me-2" />
                            </div>
                          </OverlayTrigger>
                          {professor.office || "N/A"}
                        </div>
                        <hr />
                        <div className="mb-2">
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>{localStorage.getItem("language") === "en"
                              ? "Consulatation"
                              : "Konsultacije"}</Tooltip>}
                          >
                            <div
                              target="_blank"
                              style={{ display: "inline-block" }}
                            >
                              <FaRegCalendarAlt className="me-2" />
                            </div>
                          </OverlayTrigger>

                          {professor.consultation || "N/A"}
                        </div>
                        <hr />
                        <div className="d-flex">
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>LinkedIn</Tooltip>}
                          >
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                window.open(professor.linkedin, "_blank");
                              }}
                              className="me-2"
                              style={{ cursor: "pointer" }}
                            >
                              <FaLinkedin style={{ color: "black" }} />
                            </div>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>ResearchGate</Tooltip>}
                          >
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                window.open(professor.research_gate, "_blank");
                              }}
                              className="me-2"
                              style={{ cursor: "pointer" }}
                            >
                              <FaResearchgate style={{ color: "black" }} />
                            </div>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Google Scholar</Tooltip>}
                          >
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                window.open(professor.google_scholar, "_blank");
                              }}
                              className="me-2"
                              style={{ cursor: "pointer" }}
                            >
                              <FaGoogle style={{ color: "black" }} />
                            </div>
                          </OverlayTrigger>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ))
            )}
          </div>
        )}

        <div className="d-flex justify-content-center mt-4">
          <Pagination>
            <Pagination.Prev
              onClick={() =>
                currentPage > 1 && handlePageChange(currentPage - 1)
              }
            />
            {getPaginationRange().map((page) => (
              <Pagination.Item
                key={page}
                active={page === currentPage}
                onClick={() => handlePageChange(page)}
              >
                {page}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() =>
                currentPage < totalPages && handlePageChange(currentPage + 1)
              }
            />
          </Pagination>
        </div>
      </div>
    </div>
  );
};

export default NastavnoOsobljeList;

import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import DOMPurify from "dompurify";
import DocumentsSection from "../../../components/DocumentCard";
import "../../../styles/global.css";
import Gallery from "../../../components/Galerija";
import { fetchNovostiDetail } from "../../../services/apiService";
import SectionNavbar from "../../../components/NavigacijaSekcije";
import HeadingComponent from "../../../components/HeadingComponent";
import style from "./NovostiDetaljno.module.css";

const NewsDetail = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [newsDetail, setNewsDetail] = useState(null);
  const [error, setError] = useState(null);
  const bannerRef = useRef(null);
  const slugToUse = localStorage.getItem("detailSlug")
    ? localStorage.getItem("language") === "en"
      ? JSON.parse(localStorage.getItem("detailSlug")).enSlug
      : JSON.parse(localStorage.getItem("detailSlug")).bsSlug
    : slug;

  window.history.replaceState(
    {},
    "",
    `${
      localStorage.getItem("language") === "en" ? "/en" : ""
    }/aktuelnosti/novosti/${slugToUse}`
  );

  useEffect(() => {
    const fetchNewsDetail = async () => {
      try {
        const data = await fetchNovostiDetail(slugToUse);
        setNewsDetail(data);
      } catch (error) {
        navigate("/not-found", { replace: true });
        setError("Failed to load news details. Please try again later.");
      }
    };
    fetchNewsDetail();
  }, [slug]);

  if (error)
    return <p style={{ padding: "80px", textAlign: "center" }}>{error}</p>;
  if (!newsDetail)
    return <p style={{ padding: "80px", textAlign: "center" }}>Loading...</p>;

  const items = [
    { name: "Sadržaj", nameEng: "Content", link: "#sadrzaj" },
    { name: "Dokumenti", nameEng: "Documents", link: "#dokumenti" },
    { name: "Galerija", nameEng: "Gallery", link: "#galerija" },
  ];

  // Meta Tags
  document.title = newsDetail.meta_title;
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", newsDetail.meta_description);

  console.log(newsDetail, "detaljno");
  return (
    <div>
      <HeadingComponent
        bannerRef={bannerRef}
        title={newsDetail.title}
        date={newsDetail.date}
        image={newsDetail.image}
        sectionName={
          localStorage.getItem("language") === "en" ? "News" : "Novosti"
        }
      />

      <SectionNavbar items={items} observeRef={bannerRef} />

      {/* News Content Section */}
      <div className="container__wrapper bg-white html__text">
        <Container id="sadrzaj">
          <Card className={`${style.cardWrapper} mb-4`}>
            <Card.Body>
              <Card.Text className="fs-6 fw-semibold text-dark">
                {newsDetail.summary}
              </Card.Text>
            </Card.Body>
          </Card>

          <div class={style.newsContent}>
            {/* Render content directly with sanitization */}
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(newsDetail.content),
              }}
            />
          </div>
          <div id="dokumenti">
            <DocumentsSection documents={newsDetail.documents} id="dokumenti" />
          </div>
        </Container>
      </div>

      {/* Documents and Gallery Sections */}
      <Gallery images={newsDetail.gallery} />
    </div>
  );
};

export default NewsDetail;

import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import ButtonComponent from "../../components/ButtonComponent";
import { fetchNovosti } from "../../services/apiService";
import styles from "./Vijesti.module.css";

// Custom arrow components
const NextArrow = ({ onClick, visible }) => (
  <div
    className={`${styles.arrowStyle} ${styles.nextArrow} ${
      !visible && styles.hidden
    }`}
    onClick={visible ? onClick : null} // Disable click if not visible
  >
    &rarr;
  </div>
);

const PrevArrow = ({ onClick, visible }) => (
  <div
    className={`${styles.arrowStyle} ${styles.prevArrow} ${
      !visible && styles.hidden
    }`}
    onClick={visible ? onClick : null} // Disable click if not visible
  >
    &larr;
  </div>
);

const NewsCarousel = () => {
  const [novosti, setNovosti] = useState([]);
  const [isNextArrowVisible, setIsNextArrowVisible] = useState(true);
  const [isPrevArrowVisible, setIsPrevArrowVisible] = useState(false); // Initially hide Prev arrow

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchNovosti(); // Fetch data using the service function
        setNovosti(data);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    loadData();
  }, []);

  const handleSlideChange = (current) => {
    const slidesToShow = window.innerWidth > 768 ? 3 : 1;
    const totalSlides = novosti.length;

    setIsPrevArrowVisible(current > 0);
    setIsNextArrowVisible(current + slidesToShow < totalSlides); // Show Next arrow if not on the last slide
  };

  const sliderSettings = (isDesktop) => ({
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: isDesktop ? 3 : 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow visible={isNextArrowVisible} />,
    prevArrow: <PrevArrow visible={isPrevArrowVisible} />,
    afterChange: handleSlideChange,
  });

  return (
    <section className={`container__wrapper bg-white`} id="novosti">
      <div className="container">
        <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
          <div className="mb-3">
            <h2 className="section_title">
              {localStorage.getItem("language") === "bs" ? "Novosti" : "News"}
            </h2>
            <p className="section_subtitle">
              {localStorage.getItem("language") === "bs"
                ? "Najnovije vijesti i događaji sa Mašinskog fakulteta Univerziteta u Sarajevu. Budi informisan o svim važnim dešavanjima i novostima."
                : "The latest news and events from the Faculty of Mechanical Engineering, University of Sarajevo. Be informed about all important events and news."}
            </p>
          </div>
          <Link
            to={`/aktuelnosti/novosti`}
            style={{ textDecoration: "none", color: "inherit" }}
            className="section__buttons"
          >
            <ButtonComponent
              label={
                localStorage.getItem("language") === "bs"
                  ? "Sve novosti →"
                  : "All news →"
              }
              color="#ffffff"
              backgroundColor="#051435"
            />
          </Link>
        </div>

        <Slider {...sliderSettings(window.innerWidth > 768)}>
          {novosti.map((news, index) => (
            <div key={index} style={{ padding: "0 30px" }}>
              <Link
                to={`/aktuelnosti/novosti/${news.slug}`}
                style={{ textDecoration: "none", color: "inherit" }}
                onClick={() =>
                  localStorage.setItem(
                    "detailSlug",
                    JSON.stringify({
                      bsSlug: news.slug_bs,
                      enSlug: news.slug_en,
                    })
                  )
                }
              >
                <div className={styles.cardContainer}>
                  <img
                    src={news.image}
                    alt={news.title}
                    className={styles.cardImage}
                  />
                  <div className={styles.cardDate}>{news.date}</div>
                  <div className={styles.cardContent}>
                    <h5 className={styles.cardTitle}>
                      {news.title.length > 90
                        ? news.title.substring(0, 200) + "..."
                        : news.title}
                    </h5>

                    <ButtonComponent
                      label={
                        localStorage.getItem("language") === "bs"
                          ? "Pročitaj →"
                          : "Read more →"
                      }
                      color="#000"
                      backgroundColor="#9FDAF8"
                    />
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default NewsCarousel;

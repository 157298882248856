import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
  }, [pathname]); // Runs every time the pathname changes

  return null; // This component does not render anything to the DOM
};

export default ScrollToTop;

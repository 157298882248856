import React from "react";
import NewsComponent from "./Aktuelnosti/Novosti/NovostiLista";

function Aktuelnosti() {
  return (
    <>
      <NewsComponent />
    </>
  );
}
export default Aktuelnosti;

import React from "react";
import MainBanner from "../../../components/Baner";
import ObavijestiList from "./ObavijestiList";
import { useEffect, useState } from "react";
import obavijestiBanner from "../../../assets/obavijesti.jpg";

const ObavijestiComponent = () => {
  const [language, setLanguage] = useState("bs");

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, []);

  document.title =
    language === "bs"
      ? "Obavijesti | Mašinski fakultet Univerzitet u Sarajevu"
      : "Announcements | Mechanical Engineering University of Sarajevo";
  document
    .querySelector('meta[name="description"]')
    .setAttribute(
      "content",
      "Obavijesti-Mašinski fakultet Univerziteta u Sarajevu"
    );

  return (
    <>
      <MainBanner
        title={language === "bs" ? "Obavijesti" : "Announcements"}
        bannerImage={obavijestiBanner}
        description={
          language === "bs"
            ? "Obavještenja, javne nabavke, konkursi i odluke sa Mašinskog fakulteta - Univerziteta u Sarajevu."
            : "Notices, public procurement, tenders and decisions from the Faculty of Mechanical Engineering of Sarajevo."
        }
      />
      <ObavijestiList />
    </>
  );
};

export default ObavijestiComponent;

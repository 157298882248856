import React from "react";
import "../styles/global.css";
import ButtonComponent from "./ButtonComponent";
import { Link } from "react-router-dom"; // Import Link component
import style from "./CiklusiKomponenta.module.css";

const CycleComponent = () => {
  const cycles = [
    {
      title: "Prvi ciklus studija",
      titleEN: "First cycle of studies",
      slug: `${
        localStorage.getItem("language") === "en"
          ? "first-cycle-of-studies"
          : "prvi-ciklus-studija"
      }`,
      description:
        "Prvi ciklus studija traje tri (3) godine, odnosno šest semestara sa ukupno 180 ECTS bodova nakon čega se stiče stručno zvanje Bakalaureat - inžinjer mašinstva sa naznakom smjera.",
      descriptionEN:
        "The first cycle of studies lasts three (3) years, that is, six semesters with a total of 180 ECTS points, after which the professional title of Bachelor of Mechanical Engineering with an indication of major is obtained.",
    },
    {
      title: "Drugi ciklus studija",
      titleEN: "Second cycle of studies",
      slug: `${
        localStorage.getItem("language") === "en"
          ? "second-cycle-of-studies"
          : "drugi-ciklus-studija"
      }`,
      description:
        "Drugi ciklus studija traje dvije (2) godine, odnosno četiri semestra sa ukupno 120 ECTS bodova nakon čega se stiče stručno zvanje Magistar mašinstva - diplomirani inžinjer mašinstva sa naznakom smjera.",
      descriptionEN:
        "The second cycle of study lasts two (2) years, i.e. four semesters with a total of 120 ECTS credits, after which the professional title of Master of Mechanical Engineering is acquired - graduate mechanical engineer with indication of major.",
    },
    {
      title: "Treći ciklus studija",
      titleEN: "Third cycle of studies",
      slug: `${
        localStorage.getItem("language") === "en"
          ? "third-cycle-of-studies"
          : "treci-ciklus-studija"
      }`,
      description:
        "Treći ciklus studija traje tri (3) godine, odnosno šest semestara sa ukupno 90 ECTS bodova, nakon čega se stiče stručno zvanje Doktor tehničkih nauka.",
      descriptionEN:
        "The third cycle of studies lasts three (3) years, or six semesters with a total of 90 ECTS points, after which the professional title of Doctor of Technical Sciences is obtained.",
    },
  ];

  return (
    <div className="bg-white" id="ciklusi">
      <div>
        <div className="row g-2">
          {cycles.map((cycle, index) => (
            <div
              key={index}
              className="col-lg-4 col-md-6 col-12"
              style={{ cursor: "pointer" }}
            >
              <div
                className={`${style.cycleWrapper} d-flex flex-column justify-content-between`}
              >
                <h4 className="card__title">
                  {localStorage.getItem("language") === "bs"
                    ? cycle.title
                    : cycle.titleEN}
                </h4>
                <p className="card__subtitle">
                  {" "}
                  {localStorage.getItem("language") === "bs"
                    ? cycle.description
                    : cycle.descriptionEN}
                </p>

                <Link
                  to={`/nastava/${cycle.slug}`}
                  style={{ textDecoration: "none" }}
                >
                  <ButtonComponent
                    label={
                      localStorage.getItem("language") === "bs"
                        ? "Saznaj višе →"
                        : "Learn more →"
                    }
                    color="#000"
                    backgroundColor="#9FDAF8"
                  />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CycleComponent;

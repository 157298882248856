import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import NotFoundImage from "../assets/mef-slike/404.png";

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/");
  };

  return (
    <Container className="vh-100 d-flex align-items-center justify-content-center">
      <Row className="text-center">
        <Col>
          <img
            src={NotFoundImage}
            alt="not_found_image"
            style={{ display: "block", margin: "0 auto", width: "100%" }}
          />

          <h1 className="mb-3 text-white my-4">
            {localStorage.getItem("language") === "en"
              ? "Page not found"
              : "Stranica nije pronađena"}
          </h1>
          <p className="text-white mb-5">
            {localStorage.getItem("language") === "en"
              ? "Looks like this page is either gone or just taking a break."
              : "Stranica nije pronađena"}
          </p>
          <Button
            variant="primary"
            className="text-white"
            onClick={handleGoBack}
          >
            {localStorage.getItem("language") === "en"
              ? "Back to Homepage  →"
              : "Natrag na početnu  →"}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default NotFound;

import React from "react";
import "./ButtonComponent.css";

const ButtonComponent = ({
  label,
  onClick,
  backgroundColor,
  color,
  disabled,
}) => {
  return (
    <button
      style={{
        backgroundColor: backgroundColor,
        color: color,
      }}
      className="button__style"
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default ButtonComponent;

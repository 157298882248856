import React from "react";
import "../../styles/global.css";
import CycleComponent from "../../components/CiklusiKomponenta";

const FacultyCycles = () => {
  return (
    <div className="bg-white container__wrapper" id="ciklusi">
      <div className="container">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mt-2 mb-3">
          <div>
            <h2 className="section_title">
              {localStorage.getItem("language") === "bs"
                ? "Bolonjski način studiranja"
                : "Bolongan way of study"}
            </h2>
            <p className="section_subtitle">
              {localStorage.getItem("language") === "bs"
                ? "Univerzitet u Sarajevu implementira Bolonjski način studiranja, uvažavajući vertikalnu i horizontalnu mobilnost studenata"
                : "The University of Sarajevo implements the Bolongan way of study, including vertical and horizontal mobility of students"}
            </p>
          </div>
        </div>
        <CycleComponent />
      </div>
    </div>
  );
};

export default FacultyCycles;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Use this if using react-router-dom
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.css";
import ButtonComponent from "../../components/ButtonComponent";
import { fetchOdsjeci } from "../../services/apiService";
import styles from "./Odsjeci.module.css"; // Import the CSS module

const FacultyDepartments = () => {
  const [departments, setDepartments] = useState([]);
  const navigate = useNavigate(); // For navigation to department detail page

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchOdsjeci(); // Fetch data using the service function
        setDepartments(data);
      } catch (error) {
        // Handle any errors
        console.error("Error fetching departments:", error);
      }
    };

    loadData(); // Call the function to load the data
  }, []);

  const handleCardClick = (slug) => {
    // Navigate to the department detail page
    navigate(`/odsjeci/${slug}`);
  };

  return (
    <div className="container__wrapper bg-light" id="odsjeci">
      <div className="container">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mt-2 mb-2">
          <div className="mb-3">
            <h3 className="section_title">
              {localStorage.getItem("language") === "bs"
                ? "Odsjeci"
                : "Departments"}
            </h3>
            <p className="section_subtitle col-lg-10">
              {localStorage.getItem("language") === "bs"
                ? "Mašinski fakultet Univerziteta u Sarajevu ima sedam odsjeka i ukupno devet katedri, posvećenih vrhunskom obrazovanju, naučnom istraživanju i praktičnoj nastavi."
                : "The Faculty of Mechanical Engineering of the University of Sarajevo has seven departments and a total of nine chairs, dedicated to top education, scientific research and practical teaching."}
            </p>
          </div>
        </div>
        <div className="row g-4">
          {departments.map((dept) => (
            <div
              key={dept.slug}
              className="col-lg-4 col-md-6 col-12"
              onClick={() => handleCardClick(dept.slug)} // Handle card click
            >
              <div className={`${styles.card} `} style={{ cursor: "pointer" }}>
                <div className={`${styles.cardIcon}`}>
                  <img
                    src={dept.icon}
                    alt={`${dept.name} icon`}
                    className={styles.cardImage} // Add the image styling here
                  />
                </div>

                <h4 className={`${styles.cardTitle}`}>{dept.name}</h4>

                <ButtonComponent
                  label={
                    localStorage.getItem("language") === "en"
                      ? "Read more →"
                      : "Saznaj više →"
                  }
                  color="#000"
                  backgroundColor="#9FDAF8"
                  className={styles.cardButton} // Apply button class
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering card click
                    handleCardClick(dept.slug); // Handle button click
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FacultyDepartments;

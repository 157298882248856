import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ButtonComponent from "../../components/ButtonComponent";
import { Link } from "react-router-dom";
import Logo from "../../assets/mef-slike/o-nama-sekcija.jpg";
import BlueBannerComponent from "../../components/PlaviBaner";
import style from "./Onama.module.css";

const AboutSection = () => {
  return (
    <section className="bg-light container__wrapper">
      <div className="container">
        <div className="row align-items-center">
          {/* Image Column */}
          <div className="col-lg-5 mb-4 mb-lg-0">
            <img src={Logo} alt="Masinski Fakultet" class={style.imageStyle} />
          </div>

          {/* Text Column */}
          <div className="col-lg-7">
            <h2 className="section__title">
              {localStorage.getItem("language") === "en"
                ? "The most prestigious technical faculty in BiH"
                : "Najprestižniji tehnički fakultet u BiH"}
            </h2>
            <p className="section__subtitle">
              {localStorage.getItem("language") === "en"
                ? `The Faculty of Mechanical Engineering at the University of Sarajevo, through its dynamic and interdisciplinary curriculum, aims to provide its students with
              quality education, interesting research projects, and collaboration
              with foreign faculties, institutes and business entities.`
                : `Mašinski fakultet Univerziteta u Sarajevu kroz dinamičan i interdisciplinaran nastavni program nastoji pružiti svojim studentima kvalitetno obrazovanje, zanimljive istraživačke projekte i saradnju sa inostranim fakultetima, institutima i privrednim subjektima. `}
            </p>
            <p className="section__subtitle">
              {localStorage.getItem("language") === "en"
                ? ` The
                Faculty of Mechanical Engineering provides students with academic
                education in all major areas of mechanical engineering, including
                production engineering, industrial engineering and management,
                energy, process engineering and environmental engineering, wood
                technology, motors and vehicles, defense technology, mechanical
                construction, and computer engineering.`
                : `Mašinski fakultet obezbjeđuje studentima akademsku edukaciju iz svih glavnih područja mašinskog inžinjerstva, uključujući proizvodni inženjering, industrijski inžinjering i menadžment, energetiku, procesnu tehniku i okolinsko inženjerstvo, tehnologije drveta, motore i vozila, odbrambene tehnologije, mašinske konstrukcije kao i računarsko inžinjerstvo.`}
            </p>

            <Link
              to={`/fakultet`}
              style={{ textDecoration: "none", color: "inherit" }}
              // class={style.sectionButtons}
            >
              <ButtonComponent
                label={
                  localStorage.getItem("language") === "bs"
                    ? "Više o nama →"
                    : "Read more →"
                }
                color="#000"
                backgroundColor="#9FDAF8"
              />
            </Link>
          </div>
        </div>
      </div>

      {/* Banner Section */}
      <BlueBannerComponent />
    </section>
  );
};

export default AboutSection;

import React from "react";
import "./AnimacijaLinije.css";

const AnimatedLines = () => {
  return (
    <div className="line-background">
      <div className="line" style={{ left: "20%", animationDelay: "0s" }}></div>
      <div className="line" style={{ left: "50%", animationDelay: "1s" }}></div>
      <div className="line" style={{ left: "80%", animationDelay: "2s" }}></div>
    </div>
  );
};

export default AnimatedLines;
